import { TrackUser } from './base/services/backend/track-user';
/**
 * @Author: bnockles
 * @Date:   2020-08-19T22:05:22+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-14T19:52:27+09:00
 */



import { Component, OnInit } from '@angular/core';
import { UserService } from './base/services/backend/user.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { TemplateService } from './template-variables/template.service';
import { AutoSaveService } from './base/services/auto-save.service';




@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends TrackUser implements OnInit {
  title = 'angular-playground';

  constructor(protected userService: UserService, private auth: AngularFireAuth, private templateService: TemplateService, private autoSaveService: AutoSaveService) {
    super(userService);
  }

  ngOnInit() {

  }

  get saveStatus(): string {
    return this.autoSaveService.saveStatus;
  }

  get isAdmin(): boolean {
    return this.templateService.permissions.isAdmin;
  }

  signout() {
    this.auth.signOut();
  }

}
