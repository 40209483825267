/**
 * @Author: bnockles
 * @Description: Displays all of the user's completed forms
 * @Date:   2021-03-05T21:56:38+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-18T11:04:46+09:00
 */



import { Component, OnInit, Input } from '@angular/core';
import { FormService, CompletedForm } from 'src/app/forms/form.service';
import { Unsubscriber } from 'src/app/unsubscriber.component';
import { TPG_FORM_LIBRARY } from '../tpg-form-library';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-completed-forms',
  templateUrl: './completed-forms.component.html',
  styleUrls: ['./completed-forms.component.scss']
})
export class CompletedFormsComponent extends Unsubscriber {

  formToAnswerIndexMap: { [formID: string]: string } = {};

  protected _completedFormQueryResult: CompletedForm[]
  @Input() set completedFormQueryResult(val: CompletedForm[]) {
    this._completedFormQueryResult = val;
    this.addDynamicData();
  }
  formLibrary = TPG_FORM_LIBRARY;

  constructor(protected formService: FormService, protected router: Router) {
    super();
  }

  ngOnInit() {
    //if completedFormQueryResult has not been provided, default to displaying th user's forms (from formService)
    if (!this.completedFormQueryResult) {
      this.anotherSubscription = this.formService.completedFormQueryResult$.subscribe(
        completedFormQueryResult => {
          this.completedFormQueryResult = completedFormQueryResult;
          //map every formID to its corresponding answerSheet:
          this.formToAnswerIndexMap = {};
          for (let index in this.completedFormQueryResult) {

            this.formToAnswerIndexMap[this.completedFormQueryResult[index].formID] = index;
          }
        },
        console.error
      )
    }

  }

  get completedFormQueryResult(): CompletedForm[] {
    return this._completedFormQueryResult;
  }

  /**
  add additional information to the completed form data, such as the title of the attached form and the number of completed answers out of the total
  */
  private addDynamicData() {
    if (this.completedFormQueryResult) {
      for (let answerSheet of this.completedFormQueryResult) {
        answerSheet.totalQuestions = 0;
        //iterate through all sections until form is found:
        for (let section of this.formLibrary) {
          if (section.forms[answerSheet.formID]) {
            answerSheet.totalQuestions = section.forms[answerSheet.formID].questions.length;
            answerSheet.title = section.forms[answerSheet.formID].title;
            break;
          }
        }

        //count non-trivial answers
        answerSheet.completedAnswers = 0;
        for (let answerID in answerSheet.answers) {
          if (answerSheet.answers[answerID]) {
            ++answerSheet.completedAnswers;
          }
        }


      }
    } else {
      console.warn('completed form query is null')
    }

  }

  /**********************************************
  public methods
  *********************************************/
  editForm(answers: CompletedForm) {
    //opens the form
    this.formService.openForm(answers);
    this.router.navigateByUrl('form-view')
  }


}
