
/**
 * @Author: bnockles
 * @Date:   2021-03-03T23:03:00+09:00
 * @Description: This class is a super class to any component that needs data about whether or not the user is logged in. If you need to replace the backend, use this class and rewrite the methods accordingly.
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-03T23:05:54+09:00
 */

import { OnInit } from '@angular/core';
import { UserService, AppUser } from './user.service';


export class TrackUser {

  constructor(protected userService: UserService) { }

  get appUser(): AppUser {
    return this.userService.appUser;
  }

}
