
/**
 * @Author: bnockles
 * @Date:   2021-03-04T13:19:31+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-11T00:42:53+09:00
 */


import { FormQuestionOptions, FormQuestion } from '../form-question';
import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { FormControl, ValidatorFn, AbstractControlOptions } from '@angular/forms';
import { FormComponent } from '../form-component/form-component.component';
import { APP_SETTINGS } from 'src/app/template-variables/constants';

interface FormOptionsOptions extends FormQuestionOptions {
  choices: FormOptionsChoice[];
}

export interface FormOptionsChoice {
  id: string;
  description: string;
  helpText?: string;
}


export class FormOptionsControl extends FormQuestion {


  options: FormOptionsChoice[];

  constructor(options: FormOptionsOptions, formState?: any, validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions) {
    super(options, formState, validatorOrOpts);
    this.formComponent = 'select';
    this.uniqueID = options.uniqueID;
    this.options = options.choices;
  }

}

@Component({
  selector: 'app-form-options',
  templateUrl: './form-options.component.html',
  styleUrls: ['./form-options.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      //formwardRef is needed as our class will not be defined yet when the provider is registered and we need to tell the Provider constructor about it so it can wait for the class to be defined.
      useExisting: forwardRef(() => FormOptionsComponent),
      multi: true,
    },
  ],
})
export class FormOptionsComponent extends FormComponent implements ControlValueAccessor {

  @Input() options: FormOptionsChoice[];
  @Input() viewAsCheckboxes = APP_SETTINGS.optionsDefaultCheckbox;
  @Input() allowMultiple = APP_SETTINGS.optionsDefaultMultiple;

  private _val: string[] = []; // this is a ControlValueAccessor field, for options, we save only the ID of the option
  private _selectedOptions: FormOptionsChoice[]//For the multiselect, values must be objects, so we convert to string when changing

  constructor() {
    super();
  }

  ngOnInit(): void {
  }


  get selectedOptions(): FormOptionsChoice[] {
    return this._selectedOptions;
  }

  set selectedOptions(val: FormOptionsChoice[]) {
    this._selectedOptions = val;
    this._val = [];
    for (let option of val) {
      this._val.push(option.id);
    }
    this.updateControlValueAccessor();

  }


  /*************************************************
  ControlValueAccessor methods
  ****************************************/

  private updateControlValueAccessor() {
    this.onChange(this._val);
    this.onTouch(this._val);
  }


  set value(val: string[]) {
    if (val) {
      this._val = val;
    } else {
      this._val = [];
    }
    this.updateControlValueAccessor();

  }

  get value(): string[] {
    return this._val;
  }

  isSelected(val: string): boolean {
    return this._val.includes(val);
  }

  toggle(val: string) {
    let existingIndex = this._val.indexOf(val);
    if (existingIndex > -1) {
      this._val.splice(existingIndex);
    } else {
      this._val.push(val);
    }
    this.updateControlValueAccessor();
  }

  // programmatically writing the value
  writeValue(value: any) {
    this.value = value;
  }


}
