/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-14T21:00:09+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';
import { FormTableControl } from 'src/app/forms/form-table/form-table.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const CAPTURE: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '3_6',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Capture',

  text: [
    //this text is formatted and can even take HTML
    {
      html: 'CAPTURING CONTACT INFORMATION',

      style: 'heading'
    },
    'Have you ever walked into a store, looked around, and left without engaging with the staff or being offered assistance? How about when they offer help, but you’ve responded "no thanks, I’m just browsing?"',

    'A 2017 study by Episerver reported that 92% of first-time visitors to retail websites didn’t make a purchase during that session. Without taking action, these are missed opportunities to capture contact information from an interested party. But when you create a process for capturing information so you can follow-up, you have more control over influencing a future potential transaction.',

  ],
  questions: [
    /**
    This first question might be most common for you. It is for short answers, such as "what is your name?"
    */
    new FormTableControl(
      {
        /**
        here, the unique ID must be UNIQUE

        <section name>-<description>
        */
        uniqueID: '3-6-t1',
        label: "What kinds of lead capture opportunities do you currently have each month?",
        rowHeaders: ['Website Visitors', 'Live In-Person or Virtual Events', 'Direct Mail Solicitations', 'Phone Inquiries', 'Networking Events', 'Organic Social Media', 'Social Pay-Per-Click', 'Search Engine Optimization', 'Search Pay-Per-Click', 'In Person Inquiries', 'Other'],
        columnHeaders: ['Potential Leads Per Month']
      }
    ),
    new FormTextControl(
      {
        uniqueID: '3-6-q2',
        label: "What information is most critical to be captured initially so you can follow up most effectively?",
        helpText: '(e.g. First Name, Last Name, Email Address, Phone Number, Home Address, Business Name, Business Address, etc.)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '3-6-q3',
        label: 'What is the process once the lead is captured? What happens next?',
        helpText: '(Automated or manual follow-up, assigned to individual or team, etc.)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '3-6-q4',
        label: "How can you move a new prospect into a short-term nurture process?",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '3-6-q5',
        label: 'What improvements do you need to make in your lead collection in the next 30 days?',
        multiline: true
      }
    ),
  ]
}
