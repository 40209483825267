/**
 * @Author: bnockles
 * @Date:   2021-03-05T23:12:13+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-05T23:20:06+09:00
 */



import { Directive, Input, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[appHeader]'
})
export class HeaderDirective implements OnInit {

  private view: any;
  @Input('appHeader') size: number;

  constructor(private el: ElementRef) {
    this.view = this.el.nativeElement;


  }

  ngOnInit() {
    this.view.style.fontSize = '1rem';
    if (this.size == 1) {
      this.view.style.fontSize = '3rem';
    } else if (this.size == 2) {
      this.view.style.fontSize = '2rem';
    } else if (this.size == 3) {
      this.view.style.fontSize = '1.5rem';
    }

  }

}
