/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-14T21:00:56+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';
import { FormTableControl } from 'src/app/forms/form-table/form-table.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const METRICS: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '4_3',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Metrics Framework',

  text: [
    {
      html: 'METRICS FRAMEWORK',

      style: 'heading'
    },
    'Tracking and adjusting your business practices based on KPIs (key performance indicators) is a routine activity that can help inform smart marketing decisions. However, many businesses tend to look at the wrong metrics (or the right metrics without enough context).',

    'In this exercise, we’ll look at two frameworks you can use to track and understand your “numbers” on a quarterly basis. For each framework, apply numbers from the most recently completed 3 months of business.'

  ],
  questions: [
    new FormTableControl(
      {
        uniqueID: '4-3-t1',
        label: "Framework One",
        helpText: 'Stages common to most service-based businesses: New Cold Prospects, Warm Prospects, First Time Appointment, First Time Appointments Cancelled, Discovery Appointments, Signed Clients, & Referrals.',
        rowHeaders: ['Stage 1', 'Stage 2', 'Stage 3', 'Stage 4', 'Stage 5', 'Stage 6', 'Stage 7', 'Stage 8', 'Stage 9', 'Stage 10', 'Stage 11', 'Stage 12'],
        columnHeaders: ['Number Of People']
      }
    ),
    new FormTableControl(
      {
        uniqueID: '4-3-t2',
        label: "Framework Two",
        rowHeaders: ['Action Cost (Cost per Unqualified Lead)', 'Warm Prospects (Unqualified to Qualified Ratio)', 'First Time Appointments (Lead to Appointment Ratio)', 'Signed Clients (Acquisition Cost)', 'Retained Clients (Average Customer Value)', 'Referrals (Number Per Channel)'],
        columnHeaders: ['Channel 1', 'Channel 2', 'Channel 3']
      }
    ),
    new FormTextControl(
      {
        uniqueID: '4-3-q1',
        label: 'What is your income goal? ',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '4-3-q2',
        label: 'What actions do you need to take to meet your income goal?',
        helpText: '(How Many Unqualified Leads, Prospects, etc.)',
        multiline: true
      }
    ),
  ],
}
