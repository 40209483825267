/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-09T10:00:25+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const PRICE: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '4_4',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Pricing',

  text: [
    {
      html: 'PRICING',

      style: 'heading'
    },
    'Pricing your offer should involve thoughtful consideration. However, most businesses fail to consider the reason they’ve priced things the way they have. Instead they rely on textbook formulas (e.g. percent increase above cost), industry norms, or other standard means.',

    'The activity is designed to move you away from irrelevant pricing strategy, and toward an appropriate and meaningful pricing program built around the distinctive (or unique) solution / opportunity you provide. This means factors like the following should not be considered as you think about what you charge:',

    '• Your competitors’ prices being lower than your prices',
    '• Attracting customers who buy based on low prices',
    '• A gut feeling about "what they\'ll be willing to pay"',
    '• Permitting apples-to-apples comparisons (remember your differentiation?)',
    '• Failing to offer premium price options',
    '• Feeling you don’t deserve to charge more'
  ],
  questions: [
    new FormTextControl(
      {
        uniqueID: '4-4-q1',
        label: 'How are your prices currently determined?',
        helpText: '(Value of product/service, rate for time,etc.)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '4-4-q2',
        label: 'What are the influencing factors for your current pricing?',
        helpText: '(Linked to cost, what competition charges, willingness/ability of clients to pay, etc.)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '4-4-q3',
        label: 'How does your client benefit financially after the delivery of your product/service?',
        helpText: '(Cost savings, relief from unnecessary expenses, etc.)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '4-4-q4',
        label: 'What is the value to them of your product/service?',
        helpText: '(Tangible and intangible improvements to business and/or life)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '4-4-q5',
        label: 'Why are you valuable?',
        helpText: '(Years of experience, unique deliverables, etc.)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '4-4-q6',
        label: 'What is the uppermost limit you can charge where you can still always have plenty of people willing to pay?',
        multiline: true
      }
    ),
  ],
}
