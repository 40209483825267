/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-10T15:31:30+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from 'src/app/forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const REQUEST: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '1_1',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Strategic planning overview & info request',
  text: [

    'This initial project consultation is to understand your business and provide a meaningful direction to your company\'s marketing.  We discover how and what your marketing is doing and apply fundamentals.',

    '• Predictable Revenue Framework - Understanding the current customer journey from initial interest to advocate by reviewing the following eight phases.',
    '   • Attract Traffic',
    '   • Capture Leads',
    '   • Nurture Prospects',
    '   • Convert Sales',
    '   • Transition With Easy',
    '   • Surprise, Delight, & Wow',
    '   • Upsell Clients',
    '   • Orchestrate Referrals',

    '• CRM - Examining the Current CRM Features and Implementation',
    '• Online Marketing - Examining Current Online Marketing Campaigns',
    '• Offline Marketing - Examining Current Offline Marketing Campaigns',
    '• Website / Apps - Evaluating in light of Customer Lifecycle and other marketing channels',
    '• Reporting - We gather any sufficient marketing metrics you may have in place to understand your business successes',

    //this text is formatted and can even take HTML
    {
      html: 'ACCESS/INFORMATION REQUEST',

      style: 'heading'
    },


    'Clearly understanding your business is more than just asking questions.  It is also looking at information regarding your business.  Depending on our initial findings we may ask for access to:',
    '   • Website or/and other lead capture systems',
    '   • CRM And Email Marketing Platforms',
    '   • Online Ads Platforms (Facebook, Google, Linkedin, etc.)',
    '   • Analytics Platforms',
    '   • Marketing Budgets',
    '   • Sales Pipeline / Lead Management Information',
    '   • Marketing and Sales Collateral (Brochures, Direct Mail, etc.)',

    'Reporting From:',
    '   • Previous Tradeshow events,  Marketing Campaigns (1-2 years or last 4-6 campaigns)',
    '   • Other Marketing Efforts over the past year',
    '   • Conversion Details from Previous Campaigns over the past 6-12 months',
    '   • Sales Pipeline (lead management)',

    'Financial Details that represents the following metrics:',
    '   • Customer Lifetime Value (CLTV)',
    '   • Acquisition Cost (AC)',
    '   • Profit Per Sale',
    '   • Profit Percentage per Service',

    'Any key performance indicators (KPIs) you currently use in your business.',

    'Strategic Outcome',

    '   • Goals - A marketing strategy on recommendations and next steps for the executive team',
    '   • Predictable Revenue Framework - Mapping out the desired customer lifecycle from initial interest to advocate',
    '   • Strategic Plan - A strategic plan that will detail the next steps your business should take in marketing.   This may cover items such as the path of your customer, types of traction channels used and needed, what strategies are being implemented, and technology or information need to drive future growth.  We will provide a 3-month plan that works to move you toward accomplishing your goals as laid out during the process.',
  ],
  questions: []

}
