/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-18T09:30:06+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const WOW: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '5_4',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Wow',

  text: [
    {
      html: 'BUILDING A CULTURE OF SURPRISE, DELIGHT, & WOW',

      style: 'heading'
    },

    'Many of the world\'s most greatest businesses have earned their success not because they’re the best at what they do, but because they\'ve developed raving fans. This is accomplished by overdeliving and creating exceptional, unexpected, memorable, and joyful client experiences.',

    'When carefully crafted, and programmed into the regular routine of your business activities, clients are likely to stick with you long, purchase more and more frequently, and endorse your business more readily.'
  ],
  questions: [
    new FormTextControl(
      {
        uniqueID: '5-4-q1',
        label: 'What are 3-5 things you can do to surprise, delight, and wow a brand new client?',
        helpText: '(Welcome packet, onboarding gift, special “new client” perks or discounts, etc.)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-4-q2',
        label: 'What are 3-5 things you can add to your annual schedule to continue to surprise, delight, and wow clients on an ongoing basis?',
        helpText: '(Birthday cards, invitations to events, seasonal gifts, etc.)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-4-q3',
        label: 'How can you get feedback from clients about the products / services or products they’ve purchased?',
        helpText: '(Follow-up email, survey, customer satisfaction tool, etc.)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-4-q4',
        label: 'When there’s a problem or a client is unhappy, what actions can you take to improve the situation or resolve the problem? What go-to offers can you make?',
        multiline: true
      }
    ),
  ],
  conclusionText: [
    {
      html: 'Some ways to surprise, delight, and wow your clients...',

      style: 'heading'
    },

    '• Send unexpected gifts like... books, gift cards to local businesses, a bottle of wine, something personal that represents you, something special based on their interests.',

    '• Provide special “exclusive” opportunities for your clients',

    '• Throw a client appreciation party or invite clients to participate together in a community event',

    '• Call your client just to ask how they’re doing and to hear what’s new in their life (and without ever asking for anything or even bringing up business)',

    '• Treat them to a meal, live performance, movie tickets, admission to an attraction',

    '• Make a donate on behalf of your client to a cause you support… or one they support',

    '• Send a monthly physical newsletter with personal, entertaining, and useful (non-business) content',

    '• Use a service like cardsforclients.com to automatically and regularly stay in touch',

    '• Give them a gift for their child, pet, spouse',

    '• Offer to interview them on your podcast or feature them in your newsletter'

  ],
}
