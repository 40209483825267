import { TpgTabService } from '../tpg-tab.service';
/**
 * @Author: bnockles
 * @Date:   2021-03-05T22:17:12+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-14T19:54:08+09:00
 */



import { Component, OnInit } from '@angular/core';
import { AppUser, UserService } from 'src/app/base/services/backend/user.service';
import { TemplateService } from 'src/app/template-variables/template.service';
import { APP_SETTINGS } from 'src/app/template-variables/constants';

@Component({
  selector: 'app-tpg-landing',
  templateUrl: './tpg-landing.component.html',
  styleUrls: ['./tpg-landing.component.scss']
})
export class TpgLandingComponent implements OnInit {

  companyName = APP_SETTINGS.companyName;

  constructor(private templateService: TemplateService, private userService: UserService, public tpgTabService: TpgTabService) { }

  ngOnInit(): void {
  }

  get isClient(): boolean {
    return this.templateService.permissions.isClient;
  }

  get isAdmin(): boolean {
    return this.templateService.permissions.isAdmin;
  }

  get appUser(): AppUser {
    return this.userService.appUser;
  }

}
