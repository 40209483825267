/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-18T09:29:15+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const LEAD: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '3_5',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Lead Magnets',

  text: [
    //this text is formatted and can even take HTML
    {
      html: 'CLIENT ATTRACTION',

      style: 'heading'
    },

    'Getting in front of your ideal target client doesn’t have to be challenging when you know where they are. It also can save you a lot of time and money when you focus your efforts on getting seen in fewer, more targeted places.',

    'Once you know "where" to invest your time and energy, the next step is deciding "how." What kinds of materials would be of most interest to your target market? What answers are they seeking? What problems are they aiming to overcome? And in what format or medium would they be most likely to consume what you have for them.'

  ],
  questions: [

    new FormTextControl(
      {
        uniqueID: '3-5-q1',
        label: "Where do your prospects spend their time?",
        helpText: '(Events, conferences, specific websites, social media platforms, etc.)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '3-5-q2',
        label: 'What information, answers, or special opportunities are they looking for?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '3-5-q3',
        label: "What lead magnets, if any, are you currently using? Are you happy with how they are performing?",
        helpText: '(Contests, webinars, checklists, educational guides, etc.)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '3-5-q4',
        label: 'Are there any additional lead magnet ideas worth considering?',
        multiline: true
      }
    ),
  ],
  conclusionText: [
    {
      html: 'Lead Magnet Ideas',

      style: 'heading'
    },
    'There’s an unlimited number of ways to capture a prospect\'s attention and deliver value in a lead magnet. Some of the most successful approaches involve...',
    '• Pointing out common myths or mistakes',
    '• Exploiting various pain points or frustrations',
    '• Identifying a simple process to help people get what they want',

    'Here are a few common formulas for titles or headlines worth considering:',

    'HELPFUL TIPS',
    '7 Outrageous Tips for Creating Highly Engaging Lead Magnets In Less Than 60 Minutes.',

    'PROBLEM SOLVING',
    'No More Wasting Time Creating Your Lead Magnets With This Handy Step-By-Step Guide',
    '5 Simple Actions You Can Take Today To Avoid Creating Ineffective Lead Magnets',

    'ANSWERING QUESTIONS',
    '12 Questions Every Business Must Answer Before Creating Their Lead Magnet',

    'MISTAKES & MYTHS',
    '5 Huge Lead Magnet Mistakes That Could Repel Your Best Potential Prospects',
    '9 Big Myths About Lead Magnet Creation And Why You Shouldn’t Believe Them',

    'TREND FOCUS',
    'The Biggest Lead Magnet Trends of The 21st Century',
    'Why Your Lead Magnet Must Be As Effective 5 Years From Now As It Is Today',

    'SECRETS',
    '7 High Conversion Lead Magnet Secrets Your Marketing Consultant Doesn’t Want You To Know',

    'HOW TO / WITHOUT',
    'How To Create The Best Lead Magnet Humanity Has Ever Seen Without Ever Needing To Know A Word of English',
    {
      html: 'The Eisenhower Matrix',

      style: 'heading'
    },
    'Prospects will resonate with some lead magnets more than others. So be sure to only consider offering lead magnets they recognize as both URGENT and IMPORTANT - an approach popularized by Dwight D. Eisenhower.',

    'The Eisenhower Matrix has four quadrants: Urgent & Important, Urgent & Not Important, Not Urgent & Important, and, finally, Not Urgent & Not Important. Remember, the only topics worth covering in your lead magnets must be both URGENT and IMPORTANT!'
  ],
}
