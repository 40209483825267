<ng-container *ngIf='!!appUser'>

  <div
    id="app-top-bar"
    appTopBar
    class='bar'
  >
    <div>
      <img src="/assets/logo.png" style="max-width: 320px">
    </div>


    <div style="display:flex">
      <div class="">
        <appNavLink route='home'>
          Home
        </appNavLink>
        <appNavLink *ngIf="isAdmin" route='user-management'>
          Users
        </appNavLink>
        <appNavLink *ngIf="isAdmin" route='client-management'>
          Clients
        </appNavLink>
      </div>

      <div class='gray'>
        {{ saveStatus }}
      </div>

      <appNavLink (click)='signout()'>
        Log Out
      </appNavLink>

    </div>



  </div>
</ng-container>
<div appTopBarBody>
  <router-outlet></router-outlet>
</div>
<app-footer>

</app-footer>
