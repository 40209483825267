/**
 * @Author: bnockles
 * @Date:   2021-03-03T15:20:49+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-18T11:28:17+09:00
 */



import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { StylesModule } from '../styles/styles.module';
import { BaseModule } from '../base.module';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';



@NgModule({
  declarations: [LoginComponent, ForgotPasswordComponent],
  exports: [LoginComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCardModule,
    StylesModule,
    BaseModule,
    FormsModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
  ]
})
export class LoginModule { }
