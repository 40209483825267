<h1
  *ngIf='title'
  mat-dialog-title
>
  {{ title}}
</h1>
<div mat-dialog-content>
  {{ body }}
</div>

<div mat-dialog-actions>

  <button
    mat-button
    mat-dialog-close
    cdkFocusInitial
  >OK</button>
</div>