/**
 * @Author: bnockles
 * @Date:   2021-03-12T22:42:01+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-12T22:53:43+09:00
 */



import { Component, OnInit } from '@angular/core';
import { UserManagementService } from 'src/app/users/services/user-management.service';
import { Router } from '@angular/router';
import { AppUser } from 'src/app/base/services/backend/user.service';

@Component({
  selector: 'app-tpg-user-management',
  templateUrl: './tpg-user-management.component.html',
  styleUrls: ['./tpg-user-management.component.scss']
})
export class TpgUserManagementComponent implements OnInit {

  constructor(private router: Router, private ums: UserManagementService) { }

  ngOnInit(): void {
  }

  viewUser(appUser: AppUser) {
    this.ums.userTarget$.next(appUser);
    this.router.navigateByUrl('edit-user')
  }

}
