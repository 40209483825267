/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-18T09:31:01+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const REWARD: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '6_4',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Referral Rewards',

  text: [
    'The best types of referrals are made by those who believe so strongly in you and your business that they\'ll cheerfully recommend and promote you without any prompting or incentive. Others may refer you primarily because they\'ve been incentivized by a promised reward, like cash or a gift card.  While rewarding (or at least acknowledging) referrals always is important, it’s important to understand that different people should be rewarded differently, depending on your relationship with them.'
  ],
  questions: [
    new FormTextControl(
      {
        uniqueID: '6-4-q1',
        label: 'How do you reward clients (or others) who have referred you?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '6-4-q2',
        label: 'Do you reward based on referred prospects or closed business?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '6-4-q3',
        label: 'What process can you put in place to simplify & systematize the rewarding of referrals?',
        multiline: true
      }
    ),
  ],
  conclusionText: [
    {
      html: 'Ways to reward...',

      style: 'heading'
    },

    '• Pay a cash incentive/acknowledgement',

    '• Give gift card incentive/acknowledgement',

    '• Give a gift that reflects you and/or your business',

    '• Give a gift that reflects your referrer',

    '• Make a donation to a charity in their name or of their choice',

    '• Offer free or discounted products/services.'
  ],
}
