/**
 * @Author: bnockles
 * @Date:   2021-03-13T21:23:56+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-14T15:45:17+09:00
 */



import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Unsubscriber } from 'src/app/unsubscriber.component';
import { AppClient, TpgClientService } from '../tpg-client.service';
import { APP_SETTINGS } from 'src/app/template-variables/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tpg-client-collection',
  templateUrl: './tpg-client-collection.component.html',
  styleUrls: ['./tpg-client-collection.component.scss']
})
export class TpgClientCollectionComponent extends Unsubscriber {

  @Output() onSelect = new EventEmitter<AppClient>();

  clientQueryResults: AppClient[];
  columnNames = {
    name: {
      description: 'Name',
      type: 'text',
    },
    email: {
      description: 'Email',
      type: 'email',
    },
    id: {
      description: 'Client ID',
      type: 'text'
    }
  };
  displayedColumns = ['name', 'email', 'id'];

  constructor(private cms: TpgClientService, private router: Router) {
    super();
  }

  ngOnInit(): void {
    this.anotherSubscription = this.cms.clientQueryResults$.subscribe(
      clientQueryResults => {
        this.clientQueryResults = clientQueryResults;
      }
    )
  }

  onClientClick(client: AppClient) {
    this.cms.selectClient(client).then(_ => {
      this.router.navigateByUrl('edit-client')
    })

  }

  newClient() {
    this.cms.newClient();
  }

}
