/**
 * @Author: bnockles
 * @Date:   2021-03-13T21:13:44+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-14T17:22:07+09:00
 */



import { Injectable } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';
import { QueryFn, DocumentData, AngularFirestore, DocumentSnapshot, DocumentReference } from '@angular/fire/firestore';
import { switchMap } from 'rxjs/operators';
import { AppUser } from '../base/services/backend/user.service';


export interface AppClient {
  id: string;
  members: string[];//arrray of userIDs
  memberData?: { [key: string]: AppUser }//dynamically added when a client is selected
}

@Injectable({
  providedIn: 'root'
})
export class TpgClientService {

  clientQuery$ = new ReplaySubject<QueryFn<DocumentData>>();
  clientQueryResults$ = new Observable<AppClient[]>();

  private clientTargetID: string;
  clientTarget$ = new ReplaySubject<AppClient>();

  constructor(private afs: AngularFirestore) {
    //initial query is all clients:
    this.clientQuery$.next(null);

    //whenever the query changes, update the results
    this.clientQueryResults$ = this.clientQuery$.pipe(
      switchMap(
        queryFn => {
          if (queryFn) {
            return afs.collection<AppClient>('clients', queryFn).valueChanges({ idField: 'id' })
          } else {
            return afs.collection<AppClient>('clients').valueChanges({ idField: 'id' })
          }

        }
      )

    )

  }

  /**
  looks up the members of the client and finds identifyiing information about them
  */
  selectClient(client: AppClient): Promise<void> {
    this.clientTargetID = client.id;
    //get  the userData:
    let retrieval = []
    if (client.members) {
      for (let userID of client.members) {
        retrieval.push(this.afs.doc<AppUser>('users/' + userID).get().toPromise())
      }
      return Promise.all(retrieval).then(result => {
        client.memberData = {};
        result.forEach(docRef => {
          if (docRef.exists) {
            client.memberData[docRef.id] = { id: docRef.id, ...docRef.data() };
          }
        })
        this.clientTarget$.next(client);
      })
    } else {
      client.memberData = {};
      this.clientTarget$.next(client);
      return Promise.resolve(null)
    }
  }

  saveClient(targetClient: AppClient): Promise<void> {
    return this.afs.doc('clients/' + this.clientTargetID).update(targetClient).catch(console.error);
  }

  newClient(): Promise<void | DocumentReference> {
    return this.afs.collection('clients').add({ name: 'New Client' }).catch(console.error);
  }

}
