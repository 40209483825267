/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-18T09:29:55+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const CLIENT: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '5_2',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Client Relationship Building',

  text: [
    {
      html: 'BUILDING CLIENT RELATIONSHIPS',

      style: 'heading'
    },
    'With such a high percentage of client attrition attributed to poor or lack of communication, it’s important to spend time building strong relationships with your clients.',

    'There are many ways to grow and maintain client relationships. Some are by simply being a service provider who shows how much you care about your clients. Other ways involve actively offering more value to and demonstrating your appreciation for your clients.',

    'Here are some	questions to consider to help you build a stronger relationship with your clients:'
  ],
  questions: [
    new FormTextControl(
      {
        uniqueID: '5-2-q1',
        label: 'How do you communicate with clients to make interactions with you easy, comfortable, and even enjoyable?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-2-q2',
        label: 'How do you address problems if/when they arise?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q3',
        label: 'How frequently are you proactively contacting clients for non-transactional purposes?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-4-q4',
        label: 'What can you do to programmatically provide further value to clients through education?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-5-q5',
        label: 'What can you do to programmatically demonstrate appreciation for your clients?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-6-q6',
        label: 'What else can you do to broaden your relationship with individual clients?',
        multiline: true
      }
    ),
  ],
  conclusionText: [
    {
      html: 'Additional Thoughts...',

      style: 'heading'
    },

    '• How are you actively reassuring clients, when warranted?',

    '• What are you doing to be perceived by clients as if you see them as individuals?',

    '• What are you doing to continually exceed client expectations?',

    '• How are you demonstrating to clients that you are a good listener?',

    '• Do your communications make you come across more as a fellow human or a professional service provider?',

    '   • How does your answer impact the way clients value your relationship?',

    '   • How do you create balance between these two?',

    '• Do you differentiate between transactional & relational communications?',

    '   • How do you strike a balance between the two?',

    '   • How do you create balance between these two?'
  ],
}
