/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-09T10:00:25+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const MISSION: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '2_5',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Mission',

  text: [
    //this text is formatted and can even take HTML
    {
      html: 'MISSION',

      style: 'heading'
    },

    'Your business\' mission statement is often thought of in terms of what you do and who you do it for. But, because “mission” is often tied to your “vision” and “values,” it can easily be much deeper than the basic “who” and “what” statements that so many businesses settle with.',

    'In this worksheet, we ask three basic questions to help you extract a clear and meaningful mission statement.',

  ],
  questions: [
    /**
    This first question might be most common for you. It is for short answers, such as "what is your name?"
    */
    new FormTextControl(
      {
        uniqueID: '2-5-q1',
        label: 'When it comes to the “solution” you offer as a business, what is the major “problem” you see in the world today?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-5-q2',
        label: 'Assuming that “problem” could be solved, what would a perfect world look like from your perspective?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-5-q3',
        label: 'What does your business do to take your clients from that “problem” state to the “solution” state?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-5-q4',
        label: "Based on the above, does the unique selling proposition you created earlier effectively work in tandem with your mission? If not, what changes would you make?",
        multiline: true
      }
    ),
  ]
}
