import { BaseModule } from '../base/base.module';

/**
 * @Author: bnockles
 * @Date:   2021-03-04T22:56:18+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-13T14:26:56+09:00
 */



import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormOptionsComponent } from './form-options/form-options.component';
import { FormTextComponent } from './form-text/form-text.component';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { FormViewComponent } from './form-view/form-view.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { StylesModule } from '../base/styles/styles.module';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { FormTableComponent } from './form-table/form-table.component';


@NgModule({
  declarations: [
    FormOptionsComponent,
    FormTextComponent,
    FormViewComponent,
    FormTableComponent,],
  exports: [
    FormOptionsComponent,
    FormTextComponent,
    FormViewComponent],
  imports: [
    CommonModule,
    BaseModule,
    AngularMultiSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatTableModule,
    StylesModule,
  ]
})
export class AppFormsModule { }
