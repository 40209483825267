/**
 * @Author: bnockles
 * @Date:   2020-08-19T22:05:22+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-10T21:31:45+09:00
 */



// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
//be sure to enable authent
//the following must be taken from firebase settings:
 firebase: {
   apiKey: "AIzaSyBec9-1HOGEVO5b7H_1IWdZFaxArbp4nJU",
   authDomain: "prepared-marketing-system.firebaseapp.com",
   projectId: "prepared-marketing-system",
   storageBucket: "prepared-marketing-system.appspot.com",
   messagingSenderId: "91656979486",
   appId: "1:91656979486:web:f1342e7c36b1754982da0f",
 },
//  firebase: {
//    apiKey: "AIzaSyA3Arc9Gzd4cij0jQ23AQxPRj_nX3Rq8yU",
//    authDomain: "pms-staging-c7970.firebaseapp.com",
//    projectId: "pms-staging-c7970",
//    storageBucket: "pms-staging-c7970.appspot.com",
//    messagingSenderId: "718658264579",
//    appId: "1:718658264579:web:5242ac82eb3062ba8cb836",
//    measurementId: "G-0GWKD6WZBX"
//  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
