/**d
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-10T22:26:25+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const SHORTTERMGOALS: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '1_3',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Short Term Goals',

  text: ['Goals are only as useful as you let them be. Most goals we set lack specificity or context, making them virtually impossible to achieve. That’s why the S.M.A.R.T. Goals framework was developed. S.M.A.R.T. is an acronym which has seen several revisions from its original 1981 form, but for this purpose we’ll say means Specific, Measurable, Achievable, Realistic, & Time based.',
    'In this exercise, you’ll identify multiple short-term and long-term goals, each with a timeline and tracking mechanism.'
  ],
  /**
    questions. This is where you will do most of your work. Questions define what the user will see AND how they need to  answer. YOu can use text or multiple choice questions.
    */
  questions: [
    /**
    This first question might be most common for you. It is for short answers, such as "what is your name?"
    */
    new FormTextControl(
      {
        uniqueID: '1-3-q1',
        label: "Short Term Goal #1",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-3-q2',
        label: "What’s the timeline for achieving the goal?",
        helpText: '(Consider including incremental timeline for each step in the process)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-3-q3',
        label: "How will you measure progress toward reaching the goal?",
        helpText: '(What are the key performance indicators and how will they be tracked?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-3-q4',
        label: "Short Term Goal #2",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-3-q5',
        label: "What’s the timeline for achieving the goal?",
        helpText: '(Consider including incremental timeline for each step in the process)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-3-q6',
        label: "How will you measure progress toward reaching the goal?",
        helpText: '(What are the key performance indicators and how will they be tracked?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-3-q7',
        label: "Short Term Goal #3",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-3-q8',
        label: "What’s the timeline for achieving the goal?",
        helpText: '(Consider including incremental timeline for each step in the process)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-3-q9',
        label: "How will you measure progress toward reaching the goal?",
        helpText: '(What are the key performance indicators and how will they be tracked?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-3-q10',
        label: "Long Term Goal #1",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-3-q11',
        label: "What’s the timeline for achieving the goal?",
        helpText: '(Consider including incremental timeline for each step in the process)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-3-q12',
        label: "How will you measure progress toward reaching the goal?",
        helpText: '(What are the key performance indicators and how will they be tracked?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-3-q13',
        label: "Long Term Goal #2",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-3-q14',
        label: "What’s the timeline for achieving the goal?",
        helpText: '(Consider including incremental timeline for each step in the process)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-3-q15',
        label: "How will you measure progress toward reaching the goal?",
        helpText: '(What are the key performance indicators and how will they be tracked?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-3-q16',
        label: "Long Term Goal #3",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-3-q17',
        label: "What’s the timeline for achieving the goal?",
        helpText: '(Consider including incremental timeline for each step in the process)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-3-q18',
        label: "How will you measure progress toward reaching the goal?",
        helpText: '(What are the key performance indicators and how will they be tracked?)',
        multiline: true
      }
    )
  ]
}
