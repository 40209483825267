/**
 * @Author: bnockles
 * @Date:   2021-03-05T22:35:45+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-05T22:41:01+09:00
 */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'plural' })
export class PluralPipe implements PipeTransform {
  transform(number: number, singular: string): string {
    if (number == 1) {
      return 'one ' + singular;
    } else if (number == 0) {
      return 'no ' + singular + 's';
    } else {
      return number + ' ' + singular + 's';
    }
  }
}
