/**
 * @Author: bnockles
 * @Date:   2021-03-13T15:22:49+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-14T19:49:41+09:00
 */



import { Injectable } from '@angular/core';
import { ReplaySubject, interval, timer } from 'rxjs'; import { FormGroup } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';


export class SaveOperation {
  constructor(public formGroup: FormGroup, public formPath: string, public fieldPrefix = '') {
  }


}

@Injectable({
  providedIn: 'root'
})
export class AutoSaveService {

  saveStatus: string;
  saveOperation: SaveOperation;
  source = interval(5000);


  constructor(private afs: AngularFirestore) {
    this.source.subscribe(val => {
      if (this.saveOperation) {
        if (this.saveOperation.formGroup.dirty) {
          this.saveStatus = 'Auto Saving...'
          //before save begins, mark as pristine
          this.saveOperation.formGroup.markAsPristine();
          if (this.saveOperation.fieldPrefix) {
            this.afs.doc(this.saveOperation.formPath).update(
              {
                [this.saveOperation.fieldPrefix]: this.saveOperation.formGroup.value
              }
            ).then(_ => {
              this.saveStatus = 'Progress saved.'
              timer(2000).toPromise().then(_ => {
                this.saveStatus = '';
              })
            }).catch(console.error)
          } else {
            this.afs.doc(this.saveOperation.formPath).update(this.saveOperation.formGroup.value).then(_ => {
              console.log('Progress saved')
              this.saveOperation.formGroup.markAsPristine();
              this.saveStatus = 'Progress saved.'
              timer(2000).toPromise().then(_ => {
                this.saveStatus = '';
              })
            }).catch(err => {
              console.error(err);
              this.saveStatus = 'Error saving'
            })
          }


        }
      }
    })
  }

  startMonitoring(saveOperation: SaveOperation) {
    this.saveOperation = saveOperation;
  }


}
