<div appFullView>
  <mat-card>
    <mat-card-title>
      Users
    </mat-card-title>
    <mat-card-content>
      <app-user-collection (onSelect)='viewUser($event)'>

      </app-user-collection>
    </mat-card-content>
  </mat-card>

</div>
