/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-13T13:51:46+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const COREVALUES: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '2_1',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Core Values',

  text: [
    //this text is formatted and can even take HTML
    {
      html: 'CORE VALUES',

      style: 'heading'
    },


    {
      html: '<strong>Business Growth Challenges</strong>',

      style: 'text'
    },

    'Take a moment to think beyond the obvious superficial answers here, like "not enough time" or "not enough money."'

  ],
  questions: [
    /**
    This first question might be most common for you. It is for short answers, such as "what is your name?"
    */
    new FormTextControl(
      {
        /**
        here, the unique ID must be UNIQUE

        <section name>-<description>
        */
        uniqueID: '2-1-q1',

        label: "Consider 3 to 5 challenges from areas like employees, competition, market trends, cash flow, etc. that might be getting in the way of your business' growth:",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-1-q2',
        label: "Thinking 5 years down the road, what would you like your life to look like. What do you want to have achieved, personally or professionally? Are there milestones you are aiming for? How will your personal life impact your business and vice versa?",
        multiline: true,
        text: [
          //this text is formatted and can even take HTML
          {
            html: '<strong>Your Vision for Yourself and your Business</strong>',

            style: 'text'
          },


          'Assuming you\'re not just coasting through life without a plan, let\'s take a moment to talk about your vision, both your personal vision and your business vision."'
        ],
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-1-q22',
        text: [
          //this text is formatted and can even take HTML
          {
            html: '<strong>Core Values</strong>',

            style: 'text'
          },

          'Core Values are the values that you hold inside which cannot be violated. Everyone’s core values are different, which causes each of us to react differently in certain situations For example, if your core value is “Reliability and Dependability,” you are likely to be bothered by those who are chronically late to meetings or procrastinate.',

          'Core Values drive how you act with others and what we can put up with in our businesses. If you’re not clear on our core values, you can\'t make clear decisions that keep you on the right track.',

          'The following activity is based on an approach to core values common in corporate America\’s human resources departments. We\’ve expanded on their approach to help you as a business owner understand how your core values relate to your business, colleagues, and clients.',
          {
            html: '<a href="https://drive.google.com/file/d/1jpMvRywI6NisxE499rxdjoj8mA5IlBvn/view?usp=sharing">OPEN AND PRINT THIS DOCUMENT.</a>',

            style: 'text'
          },
          'Circle each word that resonates with you. The word represents some emotion or behavior that is important to you. If you’re not sure, think about the opposite. Would it offend you if you were on the opposite end of that concept? (Example: Opposite of “Above and Beyond” is “Doing only what\’s required.” Would that offend you?)',

          'From all the words you circled, select 20 or fewer and group them in the topics below by similarity. No more than 5 groupings, please. Remember the 5 areas of your life we’re working on. Focus on those.',
        ],
        label: "Business (enter up to 5 words)",
        multiline: true,
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-1-q3',
        label: "Relationships (enter up to 5 words)",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-1-q4',
        label: "Health (enter up to 5 words)",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-1-q5',
        label: "Well Being (enter up to 5 words)",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-1-q6',
        label: "Money (enter up to 5 words)",
        multiline: true,
        text: [,

          `From each group, select one word that best represents each group. Write those 5 words below:`
        ],
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-1-q7',
        label: "Business Core Value",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-1-q8',
        label: "Relationships Core Value",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-1-q9',
        label: "Health Core Value",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-1-q10',
        label: "Well Being Core Value",
        multiline: true,
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-1-q11',
        label: "Money Core Value",
        multiline: true
      }
    ),
  ],
  conclusionText: [
    'Why was that all important?',

    'Without truly knowing what\'s hindering your business\’ growth, what vision you have for the future, and the core values you hold, it\’s nearly impossible to make decisions that align with your beliefs.',

    'These elements all work as a filter, once you know and understand them. Use them by integrating them into your life and business, so you can stay on the path to success.'
  ]
}
