/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-09T10:00:25+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const UPSELL: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '6_2',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Upsell',

  text: [
    {
      html: 'PRICING YOUR CLIENTS WITH MORE',

      style: 'heading'
    },
    'Jay Abraham said "If you truly believe that what you have is useful and valuable to your clients, then you have a moral obligation to try to serve them in every way possible."',

    'When you successfully surprise, delight, and wow your clients, they’ll be eagerly wanting to know what more you have to offer. Your responsibility is to provide them with additional value through offering products or services that complement their previous purchases or help them go further down the path they’re currently on.'

    ],
  questions: [
    new FormTextControl(
      {
        uniqueID: '6-2-q1',
        label: 'What else do you offer that is complementary to your initial (or main) offer that clients would consider a no-brainer additional purchase? ',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '6-2-q2',
        label: 'What do you not yet offer that your clients could benefit from if you did offer it? How can you add it to your offerings?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '6-2-q3',
        label: 'How and where can you make additional offers to existing clients?',
        helpText: '(When meeting with them in person, via email, via direct mail, etc.)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '6-2-q4',
        label: 'How can you add value to your clients through referrals to non-competing offers from others?',
        multiline: true
      }
    ),
  ]
}
