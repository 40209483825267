

/**
 * @Author: bnockles
 * @Date:   2021-03-03T15:21:58+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-14T12:23:03+09:00
 */


import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { Identifiable } from '../../base-classes';
import firebase from 'firebase/app';
import { switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { TemplateService } from 'src/app/template-variables/template.service';



/**
This defines all of the information we save abouot a user. If you wish to collect more or less data, update or extend this interface

The word "AppUser" is to distinguishe from "User" which is a Firebase class
*/
export interface AppUser extends Identifiable {
  email: string;
  displayName: string;
  creationDate: number;
  lastLogin: number;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  //authUser is independent from appUser since, when a person signs in for the first time, they are authenticated even though no doc exists yet.
  private _authUser: firebase.User;
  //app user is the app data that is defined from data the user generates from using the app
  private _appUser: AppUser;
  private _appUser$: Observable<AppUser>;
  private _appUserDoc: AngularFirestoreDocument<AppUser>


  /**
  AngularFireAuth is a tool for observing the current sign in status. After a user signs in (via LoginComponent) the auth will be updated and we can use the auth information to retrieve information about who signed in
  */
  constructor(private router: Router, private auth: AngularFireAuth, private afs: AngularFirestore, private templateService: TemplateService) {
    this._appUser$ = this.subscribeToAuthAndUpdateUserData();
    this._appUser$.subscribe(
      userData => {
        //userData will always have an id (because valueChanges is being used), so to make sure the user is non-trivial, we need to also check for one of the required fields
        if (userData && 'email' in userData) {
          //if the local variable _appUser is currently undefined, this is a login event, log the time
          if (!this._appUser) {
            this.afs.doc('users/' + this._authUser.uid).update({
              lastLogin: Date.now()
            }).then(_ => {
              console.log('User ' + this._authUser.uid + ' logged in')
            }).catch(console.error);
          }
          this._appUser = userData;
          //now see what permissions are allowed. This depend on the app, so we use the templateService:
          this.templateService.updatePermissions(userData);



        } else {
          //when the user signs in for the first time, a document should be created for them:
          console.log('need to create user data')
          if (this._authUser) {
            if (!this._authUser.displayName || !this._authUser.email) {
              console.error('Not enough information provided')
              throw new Error('The signin method does not provide enough information to make an account!')
            }
            this.afs.doc('users/' + this._authUser.uid).set({
              creationDate: Date.now(),
              email: this._authUser.email,
              displayName: this._authUser.displayName,
              lastLogin: Date.now()
            }).then(_ => {
              console.log('User doc initialized')
            }).catch(console.error);
          }

        }
      },
      console.error
    );

    //manage routes:
    this.auth.user.subscribe(
      user => {
        if (user) {
          this.router.navigateByUrl('home');
        } else {

          this.router.navigateByUrl('');
        }
      },
      console.error
    )

  }

  /****************************************

  PRIVATE HELPER METHODS

  *****************************************/

  /**
  Just a helper method for subscribing to FirebaseAuth and getting the User doc
  */
  private subscribeToAuthAndUpdateUserData(): Observable<AppUser> {
    return this.auth.user.pipe(
      switchMap(
        user => { //Firebase user
          if (user) {
            this._authUser = user;
            // we maintain and check the authID so that we only process
            // an event if the signed in user has changed.  This protects
            // the page from random reloads whenever we haev an angular auth
            // emission that isn't a login
            if (!this._appUser || this._appUser.id != user.uid) {
              this._appUserDoc = this.afs.doc<AppUser>('users/' + user.uid)

              //gets the data in the document and add the id field:
              return this._appUserDoc.valueChanges({ idField: 'id' });
            } else {
              //when no change has been made to the user, just return the one that has already been loaded
              return of(this._appUser);
            }
          } else {
            console.log('no user')
            return of(null);
          }


        }
      )
    );
  }

  /****************************************

  ACCESSORS

  *****************************************/
  get appUser(): AppUser {
    return this._appUser;
  }

  get appUser$(): Observable<AppUser> {
    return this._appUser$;
  }

  get userID(): string {
    return this._appUser.id;
  }
}
