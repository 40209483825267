/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-09T10:00:25+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const USP: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '2_3',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Positioning & USP',

  text: [
    //this text is formatted and can even take HTML
    {
      html: 'POSITIONING & UNIQUE SELLING PROPOSITION',

      style: 'heading'
    },

      'Your business’ role in the marketplace is made clear based on how you choose to position yourself. When you don’t take the opportunity to define your business, others will do it for you - which means you are yielding control of how your business is perceived to others.',

      'That’s where brand positioning comes in.',

      '“The basic approach of positioning is not to create something new and different, but to manipulate what\'s already up there in the mind, to retie the connections that already exist.” - Al Ries',

      'With this idea in mind, “Brand positioning” is the conceptual place you want to own in the target consumer’s mind — the benefits you want them to think of when they think of your brand.  An effective brand positioning strategy will maximize customer relevancy and competitive distinctiveness, in maximizing brand value.',
],
  questions: [
    /**
    This first question might be most common for you. It is for short answers, such as "what is your name?"
    */
    new FormTextControl(
      {
        uniqueID: '2-3-q1',
        label: 'What is your brand value?',
        helpText: '(Brand value is the thing that’s unique to you which you contribute to the marketplace)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-3-q2',
        label: "What is your brand promise?",
        helpText: '(Brand promise is the general guarantee that you make to your customers)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-3-q3',
        label: 'What is your brand identity?',
        helpText: '(Brand identity is how you want to be seen in the marketplace - the distinguishing characteristic that sets you apart from the competition)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-4-q4',
        label: "What actions do you need to take right now to ensure your brand value, promise, and identity are clear to your organization, prospects, and customers?",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-5-q4',
    text: [
      {
        html: '<strong>Formulating Your Unique Selling Proposition (U.S.P.)</strong>',

        style: 'heading'
      },
      'A Unique Selling Proposition that is understood and embraced by all stakeholders gives you a tool to measure your actions and activities against.',

      'The following list includes questions and topics which are examples to help spark ideas about where your business might focus on as you identify and articulate your position in the marketplace.',

      'LEADERSHIP',

      'Do you have unique expertise or leadership in your industry?',

      'Does the owner and or members of the staff have distinguished credentials or industry expertise?',

      'Does your business have any unique certifications that might set your apart from the competition?',

      'CUSTOMER SERVICE',

      'Is there anything you do that sets us apart in your customer service?',

      'Response time to client problems/needs?',

      'Backing of your product or service – what is your guarantee? Do you honor it? Can you guarantee stronger than the competition? Does that matter to your clients?',

      'Does your policy and procedures make it “easy” to do business with you?',

      'Have you streamlined customer service with online service? How does your return policy compare with competition? Satisfaction policy?',

      'SHIPPING / FULFILLMENT',

      'Anything distinguishing us in your shipping?',

      'Are you on time?',

      'Late?',

      'Can you or do you package better? More innovative?',

      'How long between order and product being shipped? Too long? Can you create an advantage? Is speed important to the customer?',

      'How would “FREE” shipping affect your operation?',

      'OPERATIONS',

      'Do you have an advantage in efficiency?',

      'Is your product made better than others? Better quality? What does that mean to the client? Does it matter?',

      'Are you a “lean” manufacturer? Does that give you an advantage over the competition? Does that matter?',

      'Are you the “price” leader? Do you create more value in your processes for the client? What is that value? Does it matter?',

      'Do you have a wider and better selection of products? Does that matter?',

      'Do you have better dealers and distributors? How? Does that matter? Are they helping or hurting your operations, customer service, etc.',

      'SALES AND MARKETING',

      'Do you have better salespeople? Inside? Outside? Does that create an advantage over the competition?',

      'Do you market in a unique way? Do you stand apart in our marketing/sales processes?',

      'Is your reputation strong in the industry? Online? Bad reviews? How do your dealers or distributors do?',

      'Do clients remain loyal to you? Are they leaving? Why?',

      'What is your top salesperson selling? Is he or she doing something or saying something unique to get sales at a higher level than any other?',

      'Has the competition done better target research on their customer? Better keyword and search engine optimization? Are they at the top of Google listings? In more directories? Better online reputation?',
    ],
        label: 'What is your Unique Selling Proposition?',
        helpText: 'Now that you have reviewed the above list and considered the questions at the top of this worksheet, try defining your business’ U.S.P. making sure to include all three of the following elements: [what we do/sell/solve] + [who we do it for] + [distinguishing factor]',
        multiline: true
      }
    ),
  ]
}
