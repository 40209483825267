/**
 * @Author: bnockles
 * @Date:   2021-03-03T22:38:52+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-03T22:55:43+09:00
 */



import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingComponent } from './landing/landing.component';
import { StylesModule } from '../styles/styles.module';
import { MatCardModule } from '@angular/material/card';



@NgModule({
  declarations: [LandingComponent],
  imports: [
    CommonModule,
    StylesModule,
    MatCardModule,
  ]
})
export class LandingModule { }
