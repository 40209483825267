<ng-container *ngIf='appUser else noSelectedUser'>
  <form [formGroup]='userData'>
    <ng-container *ngFor='let formKey of formData.formLayout.order'>
      <!--------------------------------------------------------
      ; let formContext = formData.formLayout.data[formKey]; let formControl = formData.formGroup[formKey]

        SELECT-BASED QUESTIONS
       ----------------------------------------------------------------------->
      <!-- <ng-container *ngIf='formContext.formComponent == "select"'>
        <app-form-options
          [label]='formContext.label'
          [helpText]='formContext.helpText'
          [options]='formContext.options'
          [formControlName]='formKey'
        >

        </app-form-options>
      </ng-container> -->



      <!--------------------------------------------------------
        TEXT-BASED QUESTIONS
       ----------------------------------------------------------------------->
      <ng-container *ngIf='formData.formLayout.data[formKey].formComponent == "text"'>
        <app-form-text
          [multiline]=formData.formLayout.data[formKey].multiline
          [label]='formData.formLayout.data[formKey].label'
          [helpText]='formData.formLayout.data[formKey].helpText'
          [formControlName]='formKey'
        >

        </app-form-text>
      </ng-container>

    </ng-container>

  </form>

  <div class="action-row align-right">
    <button
      mat-raised-button
      *ngIf="userData.dirty"
      aria-label="Save"
      (click)="saveUser()"
      color="accent"
    >
      Save
    </button>

  </div>


</ng-container>
<ng-template #noSelectedUser>
  A user has not been selected.
</ng-template>