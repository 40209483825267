/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-18T09:28:53+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const VALIDATION: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '2_7',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Validation Script',

  text: [
    //this text is formatted and can even take HTML
    {
      html: 'VALIDATION CALL SCRIPT',

      style: 'heading'
    },
    'The following script is an example of a conversation between you and individuals members within your target market. The premise of the call is to get feedback from them about your positioning and offer, in an effort to validate your assumptions. In addition, the call provides an opportunity to "sell without selling."',

    'This call is most effective when scheduled for 20-30 minutes as a request for feedback.',

    '---------------'
  ],
  questions: [
    new FormTextControl(
      {
        uniqueID: '2-7-q1',
        text: [
          'Hi (First Name)...',

          'I appreciate you taking some time to help me out. I’m going to share some new ideas I have for my business. This call should take between 20 and 30 minutes, and what I’m looking for is your complete and honest feedback.',

          'So what I’m developing is a...'
        ],
        label: '[program, product, services, etc.]',
        multiline: false
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-7-q2',
        text: [
          'to help...'
        ],
        label: '[taget market]',
        multiline: false
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-7-q3',
        text: [
          'solve the problem of...'
        ],
        label: '[problem]',
        multiline: false
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-7-q4',
        text: [
          'so they can...'
        ],
        label: '[transformation they experience]',
        multiline: false
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-7-q5',
        text: [
          'Does that all make sense so far?',

          'Okay, most folks in the...'
        ],
        label: '[target market]',
        multiline: false
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-7-q6',
        text: [
          'are currently...'
        ],
        label: '[describe their problems, frustrations, symptoms, and/or challenges]',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-7-q7',
        text: [
          'However, with the right solution, they’d be able to...'
        ],
        label: '[describe their ideal situation after overcoming their problem]',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-7-q8',
        text: [
          'With my...'
        ],
        label: '[program, product, services, etc.]',
        multiline: false
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-7-q9',
        text: [
          'I’m aiming to help them achieve that.',

          'Here’s how it works...'
        ],
        label: '[Describe the highlights of the program, product, services, etc. without getting into too much detail. Simply focus on the key points, distinctive characteristics, and “secret sauce. It may help to outline your solution step-by-step, explaining your activities and making sure to tie them to the resulting outcomes for the client]',
        multiline: true
      }
    )
  ],
  conclusionText: [
    'Any questions so far?',

    'Okay, so based on what I’ve shared, I have a few questions:',

    '1. Do the problems I’ve associated with [target market] seem legitimate?',

    '2. Does the promise of what life could be like after solving the problem seem realistic?',

    '3. If you had this problem, would it be worth investing time, energy, and/or money to solve it?',

    '4. Do you think someone like you would take advantage of my solution if it were available?',

    '5. Do you have any additional feedback for me about how I’ve framed the problem or solution?',

    '6. If I move forward with this idea, would this be something you’d be interested in discussing?',

    '7. Could you introduce me to anyone else who you think I should be speaking to for further feedback?'
  ]
}
