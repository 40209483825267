/**
 * @Author: bnockles
 * @Date:   2021-03-03T22:33:41+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-13T23:02:35+09:00
 */



import { Directive, ElementRef } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { APP_COLORS } from 'src/app/template-variables/constants';

@Directive({
  selector: '[appFullView]'
})
export class FullViewDirective {

  private view: HTMLElement;

  constructor(private el: ElementRef, public breakpointObserver: BreakpointObserver) {
    this.view = this.el.nativeElement;
    this.view.style.marginTop = '20px';
    this.view.style.marginBottom = '20px';
  }

  ngOnInit() {
    this.breakpointObserver
      .observe(['(min-width: 600px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.view.style.maxWidth = '1024px';
          this.view.style.margin = 'auto';
        } else {
          this.view.style.marginLeft = '20px'
          this.view.style.marginRight = '20px'
          this.view.style.marginTop = '20px'
          this.view.style.marginBottom = '20px'
          this.view.style.minWidth = 'inherit';
        }
      });
  }

}

@Directive({
  selector: '[appSubtle]'
})
export class SubtleDirective {

  private view: HTMLElement;

  constructor(private el: ElementRef) {
    this.view = this.el.nativeElement;
    this.view.style.color = APP_COLORS.light;
  }


}
