/**
 * @Author: bnockles
 * @Date:   2021-03-03T18:05:29+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-03T22:20:27+09:00
 */



import { Injectable } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog'
import { ConfirmComponent } from '../alert/confirm/confirm.component';
import { AlertComponent } from '../alert/alert/alert.component';

export interface Alert {
  title: string;
  type: string;
  time?: number;
  body: string;
}

export type ConfirmMode = 'warning' | 'danger' | 'normal'

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  alertSettings$ = new ReplaySubject<Alert>();

  constructor(private matDialog: MatDialog) {
    this.alertSettings$.subscribe(
      alert => {
        if (alert) {
          const dialogRef = this.matDialog.open(AlertComponent);
          dialogRef.componentInstance.body = alert.body;
          dialogRef.componentInstance.title = alert.title;
        }


      }
    )
  }

  error(body: string) {
    this.alertSettings$.next({ title: 'Error', type: 'danger', body })
  }

  success(body: string, title: string = "Success") {
    this.alertSettings$.next({ title: title, type: 'success', body })
  }

  warn(body: string, title: string = "Whoops!") {
    this.alertSettings$.next({ title: title, type: 'warning', body })
  }

  promptSubscription(body: string) {
    this.warn(body, 'Subscription Required');
  }

  confirm(body: string, title: string, confirm: () => void) {
    this.modeConfirm('normal', body, title, confirm);
  }

  warnConfirm(body: string, title: string, confirm: () => void) {
    this.modeConfirm('warning', body, title, confirm);
  }

  dangerConfirm(body: string, title: string, confirm: () => void) {
    this.modeConfirm('danger', body, title, confirm);
  }


  modeConfirm(mode: ConfirmMode, body: string, title: string, confirm: () => void) {
    const dialogRef = this.matDialog.open(ConfirmComponent);
    dialogRef.componentInstance.body = body;
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.mode = mode;
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      confirm();
    });
  }


  create(title: string, type: string, body: string, time?: number, ) {
    this.alertSettings$.next({ title, type, time, body })
  }
}
