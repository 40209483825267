import { LandingComponent } from './base/landing/landing/landing.component';
import { TPG_ROUTES } from './tpg-app/tpg-routes';
/**
 * @Author: bnockles
 * @Date:   2020-08-19T22:05:22+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-05T23:01:30+09:00
 */



import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './base/login/login/login.component';
import { redirectUnauthorizedTo, AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { TpgLandingComponent } from './tpg-app/tpg-landing/tpg-landing.component';
import { FormViewComponent } from './forms/form-view/form-view.component';

const routes: Routes = [
  {
    path: '',
    //LoginComponent is the default landing page. It presents the user with a place where they can sign in. You can replace this with a different component, such as a homepage, and put the login component at another route
    component: LoginComponent,
  },
  ...TPG_ROUTES
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {


}
