<form [formGroup]='query'>
  <app-form-text
    [multiline]=false
    label='User email address'
    helpText='Enter the email address of the author whose forms you want to view'
    formControlName='email'
    (onEnter)='editQuery("email")'
  >

  </app-form-text>

  <app-form-text
    [multiline]=false
    label='User display name'
    helpText='Enter the name of the author whose forms you want to view'
    formControlName='displayName'
    (onEnter)='editQuery("displayName")'
  >

  </app-form-text>

</form>

<app-completed-forms [completedFormQueryResult]='userForms'>

</app-completed-forms>