/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-10T15:58:15+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const FINANCIALB: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '1_2b',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Financial Goals for Advsors',

  /**
  This is an array, an ORDERED lists of elements you can put in your form
  */
  text: [
    //this text is formatted and can even take HTML
    {
      html: 'FINANCIAL GOALS FOR ADVISORS',

      style: 'heading'
    },
    'Scaling your practice is a balancing act between the amount of clients you are capable of managing, the number of prospects you need in your pipeline, and your financial goals.',

    'For example, assuming you have an established book with $20M AUM represented by 72 clients, and you are aiming to grow your practice to $500,000 in annual revenue, what adjustments must you make?',

    'A common rule of thumb is that for a well serviced client base, with high retention, you must be in contact with each client at least once per month. In addition, to continue growing and replace lost clients, you must maintain a pipeline of prospects, which also requires time.',

    'With a manageable load of 100 clients and a pipeline of 50 prospects, you must make some decisions about the minimums and distributions across your client base of investable assets that are required to reach your financial goals.'
  ],
  /**
  questions. This is where you will do most of your work. Questions define what the user will see AND how they need to  answer. YOu can use text or multiple choice questions.
  */
  questions: [
    /**
    This first question might be most common for you. It is for short answers, such as "what is your name?"
    */
    new FormTextControl(
      {
        uniqueID: '1-2b-q1',
        label: "How many clients do you currently manage?",
        multiline: false
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-2b-q2',
        label: "What is the current A.U.M. value?",
        multiline: false
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-2b-q3',
        label: "What is your A.U.M. goal for 12 months from now?",
        multiline: false
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-2b-q4',
        label: "What is your A.U.M. goal for 24 months from now?",
        multiline: false
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-2b-q5',
        label: "What is your A.U.M. goal for 5 years from now?",
        multiline: false
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-2b-q6',
        label: "Assuming a client base of 100, what does the distribution of A.U.M. across all clients need to be in 5 years?",
        helpText: '(e.g. 75% at $250k avg. and 25% at $1.5M avg.)',
        multiline: true
      }
    )
  ]
}
