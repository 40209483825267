/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-09T10:00:25+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const QUICK: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '2_4',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Quick Marketing Strategy',

  text: [
    //this text is formatted and can even take HTML
    {
      html: 'QUICK MARKETING STRATEGY',

      style: 'heading'
    },

    'This is a simple tool to help your internal team understand your market position and all be on the same page when it comes to your marketing approach.',

  ],
  questions: [
    /**
    This first question might be most common for you. It is for short answers, such as "what is your name?"
    */
    new FormTextControl(
      {
        uniqueID: '2-4-q1',
        label: 'Company Name',
        multiline: false
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-4-q2',
        label: 'Location',
        multiline: false
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-4-q3',
        label: 'Broad Industry',
        multiline: false
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-4-q4',
        label: 'Total # of Competitors in the Broad Industry',
        multiline: false
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-4-q5',
        label: 'Subcategory of Broad Industry',
        multiline: false
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-4-q6',
        label: 'Single Category Within Broad Industry',
        multiline: false
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-4-q7',
        label: 'Total # of Competitors in Single Category',
        multiline: false
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-4-q8',
        label: 'Your Advanatges',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-4-q9',
        label: 'Your Disadvanatges',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-4-q10',
        label: 'Describe the competitive landscape',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-4-q11',
        label: 'What are your beliefs about the market?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-4-q7',
        label: 'What key action(s) must you focus on?',
        multiline: true
      }
    ),
  ]
}
