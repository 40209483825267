import { LandingModule } from './base/landing/landing.module';
import { AppFormsModule } from './forms/app-forms.module';
import { TpgFormsModule } from './tpg-forms/tpg-forms.module';
import { TpgAppModule } from './tpg-app/tpg-app.module';
import { UserModule } from './users/user.module';
import { BaseModule } from './base/base.module';

/**
 * @Author: bnockles
 * @Date:   2020-08-19T22:05:22+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-13T16:17:03+09:00
 */



import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LoginModule } from './base/login/login.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { AlertModule } from './base/alert/alert.module';
import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { StylesModule } from './base/styles/styles.module';
import { FormTextComponent } from './forms/form-text/form-text.component';


@NgModule({
  declarations: [
    AppComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    StylesModule,
    AlertModule,
    LandingModule,
    AppFormsModule,
    TpgFormsModule,
    TpgAppModule,
    UserModule,
    BaseModule,
    //import the LoginModule in order to have access to the landing where a user can login
    LoginModule,
    BrowserAnimationsModule
  ],
  providers: [

    // {
    //   provide: MatDialogRef,
    //   useValue: {}
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
