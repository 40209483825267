/**
 * @Author: bnockles
 * @Date:   2021-03-10T15:39:41+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-10T22:30:43+09:00
 */



import { Directive, Input, ElementRef } from '@angular/core';
import { TextElement } from 'src/app/forms/form-model';



@Directive({
  selector: '[appTextElement]'
})
export class TextElementDirective {

  private view: HTMLElement;
  private _textContent: string | TextElement;
  @Input('textContent') set textContent(val: string | TextElement) {
    this._textContent = val;

    if (typeof val == 'string') {
      this.view.innerHTML = val;
    } else {
      const element = this._textContent as TextElement;
      this.view.innerHTML = element.html;
      if (element.style && element.style == 'heading') {
        this.view.style.fontSize = '2.0rem';

      }
    }
  };

  constructor(private el: ElementRef) {
    this.view = el.nativeElement;
    this.view.style.margin = '1rem 0.5rem';
  }

}
