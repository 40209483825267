/**
 * @Author: bnockles
 * @Date:   2021-03-03T18:17:01+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-03T21:47:05+09:00
 */



import { Component, OnInit, Input, Inject } from '@angular/core';
import { ConfirmMode } from '../../services/alert.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertComponent } from '../alert/alert.component';

export interface DialogData {
  body: string;
  title?: string;
  mode: ConfirmMode;
}

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent extends AlertComponent {

  @Input('mode') mode: ConfirmMode = 'normal';


  constructor(public dialogRef: MatDialogRef<AlertComponent>) {
    super(dialogRef);
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
