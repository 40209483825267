/**
 * @Author: bnockles
 * @Description: This is a FormControl that includes labels and help text for easier building
 * @Date:   2021-03-04T21:49:36+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-10T15:26:54+09:00
 */

import { FormControl, ValidatorFn, AbstractControlOptions } from '@angular/forms';
import { FormComponentType, TemplateControl, TextElement } from './form-model';



export interface FormQuestionOptions {
  label?: string;
  helpText?: string;
  uniqueID: string;
  text?: (TextElement | string)[]
}


export class FormQuestion extends FormControl implements TemplateControl {


  //the formComponent tells Angular which componnt to inject.
  formComponent: FormComponentType = 'text';
  //the uniqueID is used so that a user's saved responses can be identified with the source questions. That way, even if questions are re-worded or re-ordered, the answers are still aligned.
  uniqueID: string;
  label: string;
  helpText: string;
  text?: (TextElement | string)[];
  sortIndex: number;

  constructor(options: FormQuestionOptions, formState?: any, validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions) {
    super(formState, validatorOrOpts);
    this.text = options.text;
    this.uniqueID = options.uniqueID;
    this.label = options.label;
    this.helpText = options.helpText;
  }




}
