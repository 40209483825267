<ng-container *ngIf='expositoryText'>
  <div
    *ngFor='let textElement of expositoryText'
    appTextElement
    [textContent]='textElement'
  >

  </div>
</ng-container>

<div class="form-group" style="margin:0">
  <mat-label>{{ label }}</mat-label>
  <table
    mat-table
    [dataSource]="rows"
  >

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
    <ng-container
      *ngFor='let column of columnHeaders'
      [matColumnDef]="column"
    >
      <th
        mat-header-cell
        *matHeaderCellDef
      > {{ column }} </th>
      <td
        mat-cell
        *matCellDef="let element"
      >
        <ng-container *ngIf='column == "description" else input'>
          {{ element[column]}}
        </ng-container>
        <ng-template #input>
          <input
            [(ngModel)]='element[column]'
            (ngModelChange)='updateControlValueAccessor()'
          />
        </ng-template>




      </td>
    </ng-container>


    <tr
      mat-header-row
      *matHeaderRowDef="columnHeaders"
    >
    </tr>
    <tr
      mat-row
      *matRowDef="let row; columns: columnHeaders;"
    ></tr>
  </table>
</div>
