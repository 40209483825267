
/**
 * @Author: bnockles
 * @Date:   2021-03-05T22:14:57+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-14T15:41:25+09:00
 */



import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserModule } from '../users/user.module';
import { TpgLandingComponent } from './tpg-landing/tpg-landing.component';
import { TpgFormsModule } from '../tpg-forms/tpg-forms.module';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { StylesModule } from '../base/styles/styles.module';
import { TpgUserManagementComponent } from './tpg-user-management/tpg-user-management.component';
import { TpgUserComponent } from './tpg-user/tpg-user.component';
import { TpgClientComponent } from './tpg-client/tpg-client.component';
import { TpgClientCollectionComponent } from './tpg-client-collection/tpg-client-collection.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { AppFormsModule } from '../forms/app-forms.module';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [
    TpgLandingComponent,
    TpgUserManagementComponent,
    TpgUserComponent,
    TpgClientComponent,
    TpgClientCollectionComponent,
  ],
  exports: [
    TpgLandingComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    UserModule,
    StylesModule,
    MatCardModule,
    MatButtonModule,
    MatTabsModule,
    MatTableModule,
    MatIconModule,
    ClipboardModule,
    AppFormsModule,
    TpgFormsModule,
    ReactiveFormsModule
  ]
})
export class TpgAppModule { }
