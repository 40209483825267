/**
 * @Author: bnockles
 * @Description - this houses a table displaying all of the user in a given query of the users collection, however, handling of what happens on a click is emitted to a parent component
 * @Date:   2021-03-11T19:00:09+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-13T15:24:34+09:00
 */


import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import {UserService, AppUser} from 'src/app/base/services/backend/user.service';
import {Unsubscriber} from 'src/app/unsubscriber.component';
import {UserManagementService} from '../services/user-management.service';
import {APP_SETTINGS} from 'src/app/template-variables/constants';

@Component({
  selector: 'app-user-collection',
  templateUrl: './user-collection.component.html',
  styleUrls: ['./user-collection.component.scss']
})
export class UserCollectionComponent extends Unsubscriber {

  @Output() onSelect = new EventEmitter<AppUser>();

  userQueryResults: AppUser[];
  displayedColumns = APP_SETTINGS.userManagerColumns;
  columnNames = APP_SETTINGS.userManagerColumnNames;
  order=null;

  constructor(private ums: UserManagementService) {
    super();
  }

  ngOnInit(): void {
    console.log('consstructed UseCollectionComponent')
    this.anotherSubscription = this.ums.userQueryResults$.subscribe(
      userQueryResults => {
        this.userQueryResults = userQueryResults;
      }
    )
  }

  onUserClick(user: AppUser) {
    this.onSelect.emit(user);
  }

  orderByColumn(column) {
    if(this.order ==null){
      this.order = {
        column:column,
        orderType:'asc'
      }
    }
    else{
      this.order={
        column:column,
        orderType: this.order.orderType==='asc'?'desc':'asc'
      }
    }

    this.ums.orderByColumn(column, this.order.orderType);
  }

  isNullDate(date) {
    return date > Date.now();
  }
}
