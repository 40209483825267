/**
 * @Author: bnockles
 * @Date:   2021-03-05T22:51:19+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-05T22:52:21+09:00
 */



import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appHoverCursor]'
})
export class HoverCursorDirective {


  private view: any;

  constructor(private el: ElementRef) {
    this.view = this.el.nativeElement;
  }

  ngOnInit() {
  }

  @HostListener('mouseover')
  onMouseOver() {
    this.view.style.cursor = 'pointer';
  }

  @HostListener('mouseout')
  onMouseOut() {
    this.view.style.cursor = 'inherit';
  }
}
