/**
 * @Author: bnockles
 * @Date:   2021-03-13T14:20:34+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-13T14:32:58+09:00
 */



import { Component, OnInit } from '@angular/core';
import { APP_SETTINGS } from 'src/app/template-variables/constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  copyrightHolder = APP_SETTINGS.copyrightHolder

  constructor() { }

  ngOnInit(): void {
  }

}
