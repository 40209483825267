/**
 * @Author: bnockles
 * @Date:   2021-03-13T21:12:38+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-14T16:04:56+09:00
 */



import { Component, Input } from '@angular/core';
import { Unsubscriber } from 'src/app/unsubscriber.component';
import { AppClient, TpgClientService } from '../tpg-client.service';
import { FormGroupLayout } from 'src/app/base/base-classes';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TemplateService } from 'src/app/template-variables/template.service';
import { AppUser } from 'src/app/base/services/backend/user.service';
import { UserManagementService } from 'src/app/users/services/user-management.service';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/base/services/alert.service';

@Component({
  selector: 'app-tpg-client',
  templateUrl: './tpg-client.component.html',
  styleUrls: ['./tpg-client.component.scss']
})
export class TpgClientComponent extends Unsubscriber {

  formData: FormGroupLayout = {
    formLayout: {
      order: ['name', 'email'],
      data: {
        name: {
          label: 'Name',
          formComponent: 'text'
        },
        email: {
          label: 'Email',
          formComponent: 'text'
        },
      }
    },
    formGroup: new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
    })
  };
  clientData: FormGroup = this.formData.formGroup;

  targetClient: AppClient;

  constructor(private cms: TpgClientService, private router: Router, private ums: UserManagementService, private as: AlertService) {
    super();
  }

  ngOnInit(): void {
    this.anotherSubscription = this.cms.clientTarget$.subscribe(
      targetClient => {
        if (!targetClient) return;
        this.targetClient = targetClient;
        //iterate through the form's keys:
        for (let formKey in this.clientData.controls) {
          if (targetClient[formKey]) {
            this.clientData.controls[formKey].setValue(targetClient[formKey]);
          } else {
            this.clientData.controls[formKey].setValue('');
          }
        }
      }
    )

  }

  goToMember(member: AppUser) {
    this.ums.userTarget$.next(member);
    this.router.navigateByUrl('edit-user')
  }

  saveClient() {
    if (this.clientData.valid) {
      if (this.clientData.dirty) {
        this.cms.saveClient(this.clientData.value).then(_ => {
          this.as.success('All changes saved.', 'Success');
        }).catch(err => {
          this.as.error(err);
        })
      } else {
        this.as.error('There are no changes to save.')
      }

    } else {
      this.as.error('Please fill out all required fields.')
    }
  }

}
