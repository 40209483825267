/**
 * @Author: bnockles
 * @Date:   2021-03-03T22:39:06+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-03T22:53:24+09:00
 */



import { Component, OnInit } from '@angular/core';
import { UserService, AppUser } from '../../services/backend/user.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  constructor(private userService: UserService) {

  }

  get appUser(): AppUser {
    return this.userService.appUser;
  }

  ngOnInit(): void {
  }

}
