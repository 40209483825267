/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-09T10:00:25+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const LONGNUTURE: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '5_3',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Long Term Nurture',

  text: [
    {
      html: 'LONG-TERM NURTURE PLAN',

      style: 'heading'
    },
    'If you want to create a sustainable long-term business, ensuring your leads and prospects keep you top-of-mind as they get closer and closer to saying “maybe” and ultimately “yes” to your products or services, then a long-term nurture strategy is an absolute must.',

    'Communicating with them throughout the year via email, phone, and/or direct mail, you may consider focusing on seasonal themes or varying offers. In this planner, take time to map out 12 nurture topics and the corresponding messaging (what you’ll be saying) and goals (what you want to achieve) so you can start to sculpt your communications for the next year.'

  ],
  questions: [
    new FormTextControl(
      {
        uniqueID: '5-3-q1',
        text: [
          {
            html: '<strong>Month 1</strong>',

            style: 'text'
          },
        ],
        label: 'Topic',
        helpText: '(What is the general topic you will cover this month?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q2',
        label: 'Message',
        helpText: '(What is the key message you want to share with your readers?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q3',
        label: 'Goal',
        helpText: '(What is outcome you are looking for?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q4',
        label: "Notes",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q5',
        text: [
          {
            html: '<strong>Month 2</strong>',

            style: 'text'
          },
        ],
        label: 'Topic',
        helpText: '(What is the general topic you will cover this month?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q6',
        label: 'Message',
        helpText: '(What is the key message you want to share with your readers?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q7',
        label: 'Goal',
        helpText: '(What is outcome you are looking for?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q8',
        label: "Notes",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q9',
        text: [
          {
            html: '<strong>Month 3</strong>',

            style: 'text'
          },
        ],
        label: 'Topic',
        helpText: '(What is the general topic you will cover this month?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q10',
        label: 'Message',
        helpText: '(What is the key message you want to share with your readers?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q11',
        label: 'Goal',
        helpText: '(What is outcome you are looking for?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q12',
        label: "Notes",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q13',
        text: [
          {
            html: '<strong>Month 4</strong>',

            style: 'text'
          },
        ],
        label: 'Topic',
        helpText: '(What is the general topic you will cover this month?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q14',
        label: 'Message',
        helpText: '(What is the key message you want to share with your readers?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q15',
        label: 'Goal',
        helpText: '(What is outcome you are looking for?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q16',
        label: "Notes",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q17',
        text: [
          {
            html: '<strong>Month 5</strong>',

            style: 'text'
          },
        ],
        label: 'Topic',
        helpText: '(What is the general topic you will cover this month?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q18',
        label: 'Message',
        helpText: '(What is the key message you want to share with your readers?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q19',
        label: 'Goal',
        helpText: '(What is outcome you are looking for?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q20',
        label: "Notes",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q21',
        text: [
          {
            html: '<strong>Month 6</strong>',

            style: 'text'
          },
        ],
        label: 'Topic',
        helpText: '(What is the general topic you will cover this month?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q22',
        label: 'Message',
        helpText: '(What is the key message you want to share with your readers?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q23',
        label: 'Goal',
        helpText: '(What is outcome you are looking for?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q24',
        label: "Notes",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q25',
        text: [
          {
            html: '<strong>Month 7</strong>',

            style: 'text'
          },
        ],
        label: 'Topic',
        helpText: '(What is the general topic you will cover this month?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q26',
        label: 'Message',
        helpText: '(What is the key message you want to share with your readers?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q27',
        label: 'Goal',
        helpText: '(What is outcome you are looking for?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q28',
        label: "Notes",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q29',
        text: [
          {
            html: '<strong>Month 8</strong>',

            style: 'text'
          },
        ],
        label: 'Topic',
        helpText: '(What is the general topic you will cover this month?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q30',
        label: 'Message',
        helpText: '(What is the key message you want to share with your readers?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q31',
        label: 'Goal',
        helpText: '(What is outcome you are looking for?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q32',
        label: "Notes",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q33',
        text: [
          {
            html: '<strong>Month 9</strong>',

            style: 'text'
          },
        ],
        label: 'Topic',
        helpText: '(What is the general topic you will cover this month?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q34',
        label: 'Message',
        helpText: '(What is the key message you want to share with your readers?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q35',
        label: 'Goal',
        helpText: '(What is outcome you are looking for?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q36',
        label: "Notes",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q37',
        text: [
          {
            html: '<strong>Month 10</strong>',

            style: 'text'
          },
        ],
        label: 'Topic',
        helpText: '(What is the general topic you will cover this month?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q38',
        label: 'Message',
        helpText: '(What is the key message you want to share with your readers?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q39',
        label: 'Goal',
        helpText: '(What is outcome you are looking for?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q40',
        label: "Notes",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q41',
        text: [
          {
            html: '<strong>Month 11</strong>',

            style: 'text'
          },
        ],
        label: 'Topic',
        helpText: '(What is the general topic you will cover this month?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q42',
        label: 'Message',
        helpText: '(What is the key message you want to share with your readers?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q43',
        label: 'Goal',
        helpText: '(What is outcome you are looking for?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q44',
        label: "Notes",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q45',
        text: [
          {
            html: '<strong>Month 12</strong>',

            style: 'text'
          },
        ],
        label: 'Topic',
        helpText: '(What is the general topic you will cover this month?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q46',
        label: 'Message',
        helpText: '(What is the key message you want to share with your readers?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q47',
        label: 'Goal',
        helpText: '(What is outcome you are looking for?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-3-q48',
        label: "Notes",
        multiline: true
      }
    ),
  ]
}
