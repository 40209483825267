/**
 * @Author: bnockles
 * @Date:   2021-03-11T00:32:58+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-11T08:22:52+09:00
 */



import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { FormQuestion, FormQuestionOptions } from '../form-question';
import { ValidatorFn, AbstractControlOptions, NG_VALUE_ACCESSOR, ControlValueAccessor, FormGroup, AbstractControl, FormControl } from '@angular/forms';
import { FormComponent } from '../form-component/form-component.component';


interface FormTableOptions extends FormQuestionOptions {
  rowHeaders: any;
  columnHeaders: string[];
}


export class FormTableControl extends FormQuestion {

  rowHeaders: any;
  columnHeaders: string[];

  constructor(options: FormTableOptions, formState?: any, validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions) {
    super(options, formState, validatorOrOpts);
    this.formComponent = 'table';
    this.rowHeaders = options.rowHeaders;
    this.columnHeaders = options.columnHeaders;
    this.uniqueID = options.uniqueID;
  }

}

@Component({
  selector: 'app-form-table',
  templateUrl: './form-table.component.html',
  styleUrls: ['./form-table.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      //formwardRef is needed as our class will not be defined yet when the provider is registered and we need to tell the Provider constructor about it so it can wait for the class to be defined.
      useExisting: forwardRef(() => FormTableComponent),
      multi: true,
    },
  ],
})
export class FormTableComponent extends FormComponent implements ControlValueAccessor {

  private _rowHeaders: string[];
  @Input() set rowHeaders(val: string[]) {
    this._rowHeaders = val;
  };
  get rowHeaders(): string[] {
    return this._rowHeaders;
  }

  private _colmnHeaders: string[];
  @Input() set columnHeaders(val: string[]) {
    //insert a blank column
    if (!val.includes('description')) {
      val.unshift('description');
    }

    this._colmnHeaders = val;
  };
  get columnHeaders(): string[] {
    return this._colmnHeaders;
  }


  rows: any = [];//the content in the table

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  initRows() {
    this.rows = [];
    for (let rowHeader of this._rowHeaders) {
      let row = { description: rowHeader }
      for (let column of this._colmnHeaders) {
        if (column != 'description') {
          row[column] = ''
        }
      }
      this.rows.push(row);
    }
  }

  /*************************************************
  ControlValueAccessor methods
  ****************************************/

  updateControlValueAccessor() {
    this.onChange(this.rows);
    this.onTouch(this.rows);
  }


  set value(val: any[]) {
    if (val) {
      this.rows = val;
    } else {
      this.initRows();
    }
    this.updateControlValueAccessor();

  }

  get value(): any[] {
    return this.rows;
  }

  writeValue(value: any) {
    this.value = value;
  }

}
