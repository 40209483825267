import { UserService, AppUser } from '../../services/backend/user.service';

/**
 * @Author: bnockles
 * @Date:   2021-03-03T15:27:18+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-18T11:17:10+09:00
 */



import { Component, OnInit } from '@angular/core';
import firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AlertService } from '../../services/alert.service';
import { TrackUser } from '../../services/backend/track-user';
import { APP_SETTINGS } from 'src/app/template-variables/constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent extends TrackUser implements OnInit {

  loginTagline = APP_SETTINGS.loginTagline;

  userEmail: string;
  password: string;
  forgotPassword = false;

  constructor(private auth: AngularFireAuth, private as: AlertService, protected userService: UserService) {
    super(userService);
  }

  ngOnInit(): void {
  }

  /****************************************

  HELPER METHODS

  *****************************************/

  private handlePopupErrors(error, service: string) {
    var errorCode = error.code;
    var errorMessage = error.message;
    if (errorCode === 'auth/account-exists-with-different-credential') {
      this.as.error('You\'re trying to sign in using ' + service + ', but this is not the service you used last time. Please try again, using the authentication provider you normally use.')
    } else if (errorCode === 'auth/auth-domain-config-required') {
      this.as.error('This page hasn\'t yet been configured using this authentication provider. Sorry about that! Would you pleasde try another one?')
    } else if (errorCode === 'auth/cancelled-popup-request') {
      this.as.error('The login popup was cancelled. Do you already have one open? You should close it to attempt to login.')
    } else if (errorCode === 'auth/operation-not-allowed') {
      this.as.error('We haven\'t enabled authentication via ' + service + ' yet. Would you please try logging in a different way?');
    } else if (errorCode === 'auth/operation-not-supported-in-this-environment') {
      this.as.error('You\'re not allowed access in this environment. Please use a normal browser or an official Mastery Portfolio app.');
    } else if (errorCode === 'auth/popup-blocked') {
      this.as.error('The login popup was blocked by your browser.');
    } else if (errorCode === 'auth/popup-closed-by-user') {
      this.as.error('Did you mean to to close the popup? If you\'d like to log in, please try again.');
    } else if (errorCode === 'auth/unauthorized-domain') {
      this.as.error('We have some additional setup to do before you can log in using this authentication provider. Please try another method.');
    }
  }

  /****************************************

  ACCESSORS

  *****************************************/
  get appUser(): AppUser {
    return this.userService.appUser;
  }


  /****************************************

  PUBLIC METHODS

  *****************************************/

  loginGoogle() {
    //handling result: https://firebase.google.com/docs/reference/js/firebase.auth.Auth#signinwithpopup
    this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider()).catch(err => {
      this.handlePopupErrors(err, 'Google');
    });

  }

  emailLogin() {
    this.auth.signInWithEmailAndPassword(this.userEmail, this.password).catch(error => {
      var errorCode = error.code;
      var errorMessage = error.message;
      if (errorCode === 'auth/wrong-password') {
        this.as.error('The password you entered is incorrect. Please try again.')
      } else if (errorCode === 'auth/user-not-found') {
        this.as.error('A record for this user could not be found. Try a different email address or try signing in using another method.')
      } else if (errorCode === 'auth/invalid-email') {
        this.as.error('You have entered an invalid email. Please try again.')
      } else {
        alert(errorMessage);
      }
      console.log(error);
    })
  }

}
