/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-09T10:00:25+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const BRAND: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '3_1',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Brand Audit',

  text: [
    //this text is formatted and can even take HTML
    {
      html: 'BRAND AUDIT',

      style: 'heading'
    },
    'Now that you have established clarity around your business positioning and who you are targeting in your marketing efforts, it’s important to review the materials you currently have in place.',

    'This exercise provides you the opportunity to revise or refine anything which may no longer be congruent with the decisions you’ve recently made.',
    {
      html: '<strong>Review the following assets:</strong>',

      style: 'text'
    },
  ],
  questions: [
    new FormOptionsControl(
      {
        uniqueID: '3-1-q1',
        label: 'PRINT COLLATERAL',
        choices: [
          {
            id: 'choice1',
            description: 'Business cards'
          },
          {
            id: 'chocie2',
            description: 'Letterhead'
          },
          {
            id: 'choice3',
            description: 'Envelopes'
          },
          {
            id: 'chocie4',
            description: 'Notepads'
          },
          {
            id: 'choice5',
            description: 'News releases'
          },
          {
            id: 'chocie6',
            description: 'Invitations'
          },
          {
            id: 'choice7',
            description: 'Flyers/One-Sheets/Templates'
          },
          {
            id: 'chocie8',
            description: 'Postcards & Direct Mail'
          },
        ]
      }),
      new FormOptionsControl(
        {
          uniqueID: '3-1-q2',
          label: 'MESSAGING & STORY',
          choices: [
            {
              id: 'choice1',
              description: 'Tagline'
            },
            {
              id: 'chocie2',
              description: 'Brand, tone & voice'
            },
            {
              id: 'choice3',
              description: 'Vocabulary'
            },
            {
              id: 'chocie4',
              description: 'Key & featured messaging'
            },
            {
              id: 'choice5',
              description: 'Key features & benefits'
            },
            {
              id: 'chocie6',
              description: 'Calls to action'
            },
            {
              id: 'choice7',
              description: 'Elevator speech'
            },
          ]
        }),
        new FormOptionsControl(
          {
            uniqueID: '3-1-q3',
            label: 'DIGITAL',
            choices: [
              {
                id: 'choice1',
                description: 'Email newsletters'
              },
              {
                id: 'chocie2',
                description: 'Email signatures'
              },
              {
                id: 'choice3',
                description: 'Social network profiles (Facebook, LinkedIn, Twitter, Pinterest, Instagram, YouTube, etc.)'
              },
              {
                id: 'chocie4',
                description: 'Website'
              },
              {
                id: 'choice5',
                description: 'Blog'
              },
              {
                id: 'chocie6',
                description: 'Videos (including intros and outros)'
              },
              {
                id: 'choice7',
                description: 'Display Advertising'
              },
              {
                id: 'chocie8',
                description: 'Search Advertising'
              },
            ]
          }),

          new FormOptionsControl(
            {
              uniqueID: '3-1-q4',
              label: 'BRAND HIERARCHY',
              choices: [
                {
                  id: 'choice1',
                  description: 'Umbrella brand'
                },
                {
                  id: 'chocie2',
                  description: 'Sub or family brands'
                },
                {
                  id: 'choice3',
                  description: 'Product brands'
                },
                {
                  id: 'chocie4',
                  description: 'Event brands'
                },
                {
                  id: 'choice5',
                  description: 'Program brands'
                },
              ]
            }),
            new FormOptionsControl(
              {
                uniqueID: '3-1-q5',
                label: 'EXHIBITS',
                choices: [
                  {
                    id: 'choice1',
                    description: 'Trade show booth'
                  },
                  {
                    id: 'chocie2',
                    description: 'Banners'
                  },
                  {
                    id: 'choice3',
                    description: 'Name tags'
                  },
                  {
                    id: 'chocie4',
                    description: 'Premiums (giveaways)'
                  },
                  {
                    id: 'choice5',
                    description: 'Handouts'
                  },
                ]
              },
      Validators.required),
    new FormTextControl(
      {
        uniqueID: '3-1-q6',
        label: 'What other changes do you need to consider making to align with your brand identity, USP, & chosen target market(s)?',
        multiline: true
      }
    ),
  ]
}
