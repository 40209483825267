/**
 * @Author: bnockles
 * @Date:   2021-03-11T19:01:22+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-13T22:33:18+09:00
 */



import { Component, OnInit, Input } from '@angular/core';
import { Unsubscriber } from 'src/app/unsubscriber.component';
import { AppUser } from 'src/app/base/services/backend/user.service';
import { TemplateService } from 'src/app/template-variables/template.service';
import { FormGroupLayout } from 'src/app/base/base-classes';
import { UserManagementService } from '../services/user-management.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.scss']
})
export class UserViewComponent extends Unsubscriber {

  @Input() appUser: AppUser;

  formData: FormGroupLayout;
  userData: FormGroup;

  constructor(private templateService: TemplateService, private ums: UserManagementService) {
    super();
    this.formData = this.templateService.userEditForm;
    this.userData = this.formData.formGroup;


  }

  ngOnInit(): void {
    //populate the form with values from the target user's doc:
    this.anotherSubscription = this.ums.userTarget$.subscribe(
      targetUser => {
        if (!targetUser) return;
        //iterate through the form's keys:
        for (let formKey in this.userData.controls) {
          if (targetUser[formKey]) {
            this.userData.controls[formKey].setValue(targetUser[formKey]);
          }
        }

      },
      console.error
    )
  }

  saveUser() {
    //remove the user's client ID if it hasn't been changed
    if (this.userData.valid) {
      this.templateService.saveUser(this.userData, this.appUser.id);
    }
  }

}
