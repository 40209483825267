/**
 * @Author: bnockles
 * @Date:   2021-03-14T19:55:43+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-14T20:46:15+09:00
 */



import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { CompletedForm } from 'src/app/forms/form.service';
import { Unsubscriber } from 'src/app/unsubscriber.component';
import { TpgFormReviewService } from '../tpg-form-review.service';
import { AlertService } from 'src/app/base/services/alert.service';

@Component({
  selector: 'app-form-query',
  templateUrl: './form-query.component.html',
  styleUrls: ['./form-query.component.scss']
})
export class FormQueryComponent extends Unsubscriber {

  query = new FormGroup({
    email: new FormControl(''),
    displayName: new FormControl('')
  })

  userForms: CompletedForm[];

  constructor(private formReviewService: TpgFormReviewService, private as: AlertService) {
    super();
  }

  ngOnInit(): void {
    this.anotherSubscription = this.formReviewService.formQueryResults$.subscribe(
      results => {
        if (results) {
          console.log(results)
          this.userForms = results;
        }

      },
      console.error
    )
  }

  /**********************************************
  public methods
  *********************************************/
  editQuery(field: string) {
    let value = this.query.controls[field].value.trim();
    if (value) {
      this.formReviewService.queryFormsByField(this.query.controls[field].value, field).catch(err => {
        this.as.error(err);
      });
    }

  }

}
