<div appFullView>
  <mat-card *ngIf='targetClient else noSelectedClient'>
    <mat-card-title>
      {{ targetClient.name }}
    </mat-card-title>
    <mat-card-content>

      <form [formGroup]='clientData'>
        <div class="form-group client-id">
          <label for="clipboard-example-textarea">Client ID: <span class='accent '>{{ targetClient.id }}</span></label>
          <button
            mat-raised-button
            [cdkCopyToClipboard]="targetClient.id"
          >Copy to clipboard</button>
        </div>


        <ng-container *ngFor='let formKey of formData.formLayout.order'>
          <ng-container *ngIf='formData.formLayout.data[formKey].formComponent == "text" else notSupported'>
            <app-form-text
              [multiline]=formData.formLayout.data[formKey].multiline
              [label]='formData.formLayout.data[formKey].label'
              [helpText]='formData.formLayout.data[formKey].helpText'
              [formControlName]='formKey'
            >

            </app-form-text>
          </ng-container>
          <ng-template #notSupported>
            The forrm control for {{ formKey }} of type {{ formData.formLayout.data[formKey].formComponent }} is not supported. Use type "text".
          </ng-template>


        </ng-container>

        <!-----------------------------------------------------
        Save Button
       ------------------------------------------------------>
        <div class="action-row align-right">
          <button
            mat-raised-button
            *ngIf="clientData.dirty"
            aria-label="Save"
            (click)="saveClient()"
            color="accent"
          >
            Save
          </button>

        </div>
        <!-----------------------------------------------------
        List the members
       ------------------------------------------------------>
        <h2>Organization Members</h2>
        <div appSubtle>
          Members must be added through editing the users. Once added, they will appear here.
        </div>
        <ul *ngIf='targetClient.memberData'>
          <li *ngFor='let member of targetClient.memberData | keyvalue'>
            <button
              mat-button
              (click)='goToMember(member.value)'
            >
              {{member.value.displayName}}
            </button>
          </li>

        </ul>

      </form>


    </mat-card-content>
  </mat-card>
  <ng-template #noSelectedClient>
    A client has not been selected.
  </ng-template>
</div>