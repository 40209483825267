import { AVATAR } from './question-files/2_positioning_usp/6_client_avatar';
import { VALIDATION } from './question-files/2_positioning_usp/7_validation_script';
import { ROAD } from './question-files/2_positioning_usp/8_road_less_stupid';
import { BUYER } from './question-files/3_front_end_management/2_buyer_journey';
import { CRM } from './question-files/3_front_end_management/3_crm_personalization';
import { TRACTION } from './question-files/3_front_end_management/4_traction';
import { LEAD } from './question-files/3_front_end_management/5_lead_magnets';
import { CAPTURE } from './question-files/3_front_end_management/6_capture';
import { SHORTNURTURE } from './question-files/3_front_end_management/7_short_term_nuture';
import { BRAND } from './question-files/3_front_end_management/1_brand_audit';
import { STRUCTURE } from './question-files/4_offer_conversion/1_offer_structure';
import { PRESENTATION } from './question-files/4_offer_conversion/2_offer_presentation';
import { METRICS } from './question-files/4_offer_conversion/3_metrics_framework';
import { PRICE } from './question-files/4_offer_conversion/4_pricing';
import { SELLING } from './question-files/4_offer_conversion/5_selling';
import { CLIENT } from './question-files/5_stick_programming/2_client_relationship_building';
import { LONGNUTURE } from './question-files/5_stick_programming/3_long_term_nuture';
import { WOW } from './question-files/5_stick_programming/4_wow';
import { EMAIL } from './question-files/5_stick_programming/5_email_ideas';
import { ONBOARDING } from './question-files/5_stick_programming/1_onboarding';
import { PARTNERS } from './question-files/6_revenu_expansion/1_strategic_partners';
import { UPSELL } from './question-files/6_revenu_expansion/2_upsell';
import { REFERRAL } from './question-files/6_revenu_expansion/3_referrals';
import { REWARD } from './question-files/6_revenu_expansion/4_referral_rewards';

/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-04T21:45:04+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-18T10:39:59+09:00
 */

/**
These are imports, this is the section of the file where you can make references to other files. It is rcommendd yoou piut every form in its own  file for better organization. You can import those files here:
*/

//This first  import is related to a code file:
import { FormSection } from '../forms/form-model';

//This import is for the first form, the sample form. YOur future imports will  look like this:
import { REQUEST } from './question-files/1_opportunity_analysis/1_request_for_information';
import { FINANCIALA } from './question-files/1_opportunity_analysis/2a_financial_goals';
import { FINANCIALB } from './question-files/1_opportunity_analysis/2b_financial_goals';
import { SAMPLE_FORM } from './question-files/-USE-THIS-EXAMPLE';
import { SHORTTERMGOALS } from './question-files/1_opportunity_analysis/3_short_long_term_goals';
import { NEEDSASSESSMENT } from './question-files/1_opportunity_analysis/4_needs_assesment';
import { COREVALUES } from './question-files/2_positioning_usp/1_core_values';
import { TARGETMARKET } from './question-files/2_positioning_usp/2_target_market';
import { USP } from './question-files/2_positioning_usp/3_positioning_usp';
import { QUICK } from './question-files/2_positioning_usp/4_quick_marketing_strategy';
import { MISSION } from './question-files/2_positioning_usp/5_mission';


export const TPG_FORM_LIBRARY: FormSection[] = [
  /**************************************************
  SECTION 1 - Example Section
  *************************************************/
  {
    /**
    The header will appear at the top of the section. Notice that the nameis writtn in quotation marks. Both single or double quotatiion marks are acceptible. I am in the habit of using single quotation marks for fles ending with .ts
    */
    header: 'Opportunity Analysis',
    forms: {
      /**
      You can remove the following, which is here only to serve as an example:

      'sample1' is the form's KEY. It is used to save the form on the database and to help you identify which form is attached to which set of answers. Make sure EVERY key is different.

      SAMPLE_FORM is the name of the variable for the corresponding form in another file. You can see the import of this form at the top of this file, in the imports section

      */
      // sample0: SAMPLE_FORM,
      '1_1': REQUEST,
      '1_2a': FINANCIALA,
      '1_2b': FINANCIALB,
      '1_3': SHORTTERMGOALS,
      '1_4': NEEDSASSESSMENT,
      /*
      moving forward, addtional forms should  be listed like this (ignore the slashes, they are they simple "comment out" the line of code):
      sample2: SAMPLE_FORM_2,
      sample3: SAMPLE_FORM_3,
      sample4: SAMPLE_FORM_4,
      etc...
      */
    }
  },
  /**************************************************
  SECTION 2 - A note on adding more sections:
  You can divide your forms into sections. Each section has a title and a 'key-value map' of forms.

  For example:
*************************************************/
  {
    header: "Positioning",
    forms: {
      '2_1': COREVALUES,
      '2_2': TARGETMARKET,
      '2_3': USP,
      '2_4': QUICK,
      '2_5': MISSION,
      '2_6': AVATAR,
      '2_7': VALIDATION,
      '2_8': ROAD
    }
  },
  {
    header: "Front End",
    forms: {
      '3_1': BRAND,
      '3_2': BUYER,
      '3_3': CRM,
      '3_4': TRACTION,
      '3_5': LEAD,
      '3_6': CAPTURE,
      '3_7': SHORTNURTURE
    }
  },
  {
    header: "Offer Conversion",
    forms: {
      '4_1': STRUCTURE,
      '4_2': PRESENTATION,
      '4_3': METRICS,
      '4_4': PRICE,
      '4_5': SELLING,
    }
  },
  {
    header: "Stick Programming",
    forms: {
      '5_1': ONBOARDING,
      '5_2': CLIENT,
      '5_3': LONGNUTURE,
      '5_4': WOW,
      '5_5': EMAIL,
    }
  },
  {
    header: "Revenue Expansion",
    forms: {
      '6_1': PARTNERS,
      '6_2': UPSELL,
      '6_3': REFERRAL,
      '6_4': REWARD,
    }
  }


]
