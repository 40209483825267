/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-09T10:00:25+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const ROAD: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '2_8',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Success Proposition',
  text: [
    //this text is formatted and can even take HTML
    {
      html: 'SUCCESS PROPOSITION EXERCISE',

      style: 'heading'
    },
    {
      html: 'This exercise is excerpted from <a href="https://amzn.to/3qgcpRp">"The Road Less Stupid"</a> by Keith J. Cunningham',

      style: 'text'
    },


    'This exercise is excerpted from “The Road Less Stupid” by Keith J. Cunningham',

    '“Potential customers in your target market who are familiar with your business but still have not bought from you are generally rejecting your solution for one of four reasons.',

    '“1. Risk: Target customers do not buy if they perceive a risk of failure or have a fear that your solution will not work for them. If they doubt that your product or service will work as effectively or efficiently as the product they are currently buying or that something could go wrong, they will not make the switch.',

    '“2. Friction: Sometimes potential customers do not buy because the brain damage associated with switching is too great. They look at all the friction costs that would be incurred and resources consumed in the set-up, retraining, installation, adoption, de-installation, breaking old habits, and implementation of new procedures and processes and realize the pain of the change is not worth the gain. Minimizing friction costs is a critical driver for growth.',

    '“3. The Difference That Makes the Difference: Confused customers rarely buy. If a potential customer does not have a clear understanding of what you can do for them that is meaningful/important and is not available elsewhere, they are unlikely to make the switch.',

    '“Here is the key: The only way you can describe the difference between you and everybody else who wants their business is to have a granular understanding of what your competitors do and how what you do is meaningfully different. You must be able to tell a customer: “This is what we do and here is why this is important and unique. Other vendors do not do this; they do that. If you want that, you should buy from them. If you want this, you should buy from us.” (The ominously titled chapter “The Triangle of Death” underscores the risk of failing to communicate this message. You might also take a look at “Simplifying Growth” for additional thoughts on this subject.)',

    '“4. Success Proposition: Here it is on a bumper sticker: Value created but not delivered is of no value. Value delivered but not perceived is of no value. In other words, it does no good to wink at a pretty girl with the lights out . . . you’re the only one who knows what you did. If you want to get noticed, turn the lights on...',

    '"Few growth strategies are more powerful than giving the customer certainty of success." - From “The Road Less Stupid” by Keith J. Cunningham',

    {
      html: 'CUNNINGHAM’S THINKING QUESTIONS',

      style: 'heading'
    },

    'GENERAL',

    '• Why aren’t your sales 2X bigger than they are today (really)?',

    '• Competitively, why do customers buy from us?',

    '• What could happen to cause a potential customer to not buy from me?',

    '• What can I do to eliminate these issues?',

    'RISK',

    '• What is my competition messaging or doing that is causing my ideal target customer to do business with them and not with me?',

    '• What specific risks, concerns, or fears do potential customers perceive that might be blocking their willingness to do business with me?',

    '• If I am asking a customer to stop doing business with someone else and to start doing business with us, what are the risks they are imagining in making this switch?',

    'FRICTION',

    '• What are the specific friction points that cause customers to hesitate or decline doing business with us?',

    '• How might these friction points be mitigated or addressed?',

    '• What is the frictionless, compelling promise we should make? Is this promise clear, unique, and strong enough to cause them to switch to us?',

    'THE DIFFERENCE THAT MAKES THE DIFFERENCE',

    '• What is the DIFFERENCE that makes the DIFFERENCE to the unsold target buyer?',

    '• Is this difference the difference that makes the difference . . . or is it something else?',

    '• What is the one thing that would be the difference that makes the difference for our target market and cause these people to immediately understand why this difference is important and meaningful?',

    '• Is the message (promise) we are delivering to potential clients clear on the solution we offer that the competition cannot or does not offer?',

    '• How does this message need to be communicated more effectively?',

    'SUCCESS PROPOSITION',

    '• How (specifically) does the unsold buyer define “success?”',

    '• Competitively, does the value we are delivering to the client exceed the price we are charging? How do we know? What needs to happen to be sure?',

    '• What expectations do our target customers have?',

    '• How do we meet these expectations more effectively than our competition?',

    '• What must happen to meet/exceed the customers’ expectations and cause them to experience certainty of success?'
  ],
  questions: []

}
