<div appFullView>
  <mat-card *ngIf='targetUser'>
    <mat-card-title>
      {{ targetUser.displayName }}
    </mat-card-title>
    <mat-card-content>
      <app-user-view [appUser]='targetUser'>

      </app-user-view>
    </mat-card-content>
    <mat-divider>

    </mat-divider>
    <mat-card-title>
      Completed Forms
    </mat-card-title>
    <mat-card-content>

      <app-completed-forms [completedFormQueryResult]='userForms'>

      </app-completed-forms>
    </mat-card-content>
  </mat-card>
</div>