import { APP_COLORS } from '../../template-variables/constants';
/**
 * @Author: bnockles
 * @Date:   2021-03-03T23:08:46+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-13T22:12:15+09:00
 */



import { Directive, ElementRef, Input, QueryList, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Directive({ selector: 'appNavLink' })
export class NavLink {
  @Input() route: string;

  private view: HTMLElement;

  constructor(private el: ElementRef, private router: Router) {
    this.view = this.el.nativeElement;
    this.view.style.display = 'inline-block';
    this.view.style.marginTop = '0.5rem';
    this.view.style.marginRight = '1.5rem';
    this.view.style.color = APP_COLORS.link;
  }

  @HostListener('mouseover')
  onMouseOver() {
    this.view.style.cursor = 'pointer';
    this.view.style.color = APP_COLORS.hoverLink;
  }

  @HostListener('mouseout')
  onMouseOut() {
    this.view.style.cursor = 'inherit';
    this.view.style.color = APP_COLORS.link;
  }

  @HostListener('click')
  onClick() {
    this.router.navigateByUrl(this.route);
  }
}



@Directive({
  selector: '[appTopBar]'
})
export class TopBarDirective {

  private view: HTMLElement;

  constructor(private el: ElementRef) {
    this.view = this.el.nativeElement;
    this.view.style.position = 'fixed';
    this.view.style.top = '0';
    this.view.style.left = '-10px';
    this.view.style.right = '-10px';
    this.view.style.height = '35px';
    this.view.style.zIndex = '900';
    this.view.style.display = 'flex';
    this.view.style.justifyContent = 'space-between'
    this.view.style.backgroundColor = APP_COLORS.dark;
    this.view.style.padding = '10px 20px';
    this.view.style.boxShadow = '0px 4px 5px -2px rgba(0,0,0,0.72)';
    this.view.querySelectorAll('a').forEach(
      element => {
        element.style.fontSize = '1.25rem';
      }
    )
  }

}


@Directive({
  selector: '[appTopBarBody]'
})
export class TopBarBodyDirective {

  private view: HTMLElement;

  constructor(private el: ElementRef) {
    this.view = this.el.nativeElement;
    this.view.style.marginTop = '80px';

  }

}
