/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-09T10:00:25+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const ONBOARDING: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '5_1',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Onboarding',

  text: [
    {
      html: 'EASE OF TRANSITION',

      style: 'heading'
    },

    'At the moment someone says “yes” to becoming a client, two things can happen: 1. Buyer’s remorse can set in, or 2. they’re congratulating themselves for the wise decision they just made. Either way, what you do to ease the transition, and put a smile on their face, matters.',

  ],
  questions: [
    new FormTextControl(
      {
        uniqueID: '5-1-q1',
        label: 'What are 3-5 common barriers (actual or perceived) in your business that create friction in onboarding a new client?',
        helpText: '(scheduling delay, paperwork delay, relying on a 3rd party, fulfillment won’t be immediate, etc.)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '5-2-q2',
        label: 'What changes do you need to make to your onboarding process to reduce friction and transition with greater ease?',
        multiline: true
      }
    ),
  ]
}
