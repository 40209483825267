/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-09T10:00:25+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const AVATAR: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '2_6',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Ideal Client Avatar',

  text: [
    //this text is formatted and can even take HTML
    {
      html: 'IDEAL CLIANT AVATAR',

      style: 'heading'
    },

    'Your marketing message (and many of the decisions you make related to marketing, positioning, and offers) is most effective when you know EXACTLY who you are serving. That’s the magic of the ideal client avatar - which is a singular individual representative of your ideal clients.',

    'The following questions will help you paint a clear and detailed picture of who they are now as well as who/what they can become/achieve once they’ve become your client.',

  ],
  questions: [
    /**
    This first question might be most common for you. It is for short answers, such as "what is your name?"
    */
    new FormTextControl(
      {
        uniqueID: '2-6-q1',
        text: [
          //this text is formatted and can even take HTML
          {
            html: '<strong>Who Are They Now?</strong>',

            style: 'text'
          },
          'Personal'
        ],
        label: 'Name',
        multiline: false
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q2',
        label: 'Marital Status',
        multiline: false
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q3',
        label: 'Gender',
        multiline: false
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q4',
        label: 'Age',
        multiline: false
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q5',
        label: 'How many children (and their ages)?',
        multiline: false
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q6',
        label: 'Where do they live?',
        multiline: false
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q7',
        text: [
        'Professional & Money'
      ],
        label: 'Occupation',
        multiline: false
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q8',
        label: 'Job title/Roll in organization',
        multiline: false
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q9',
        label: 'Annual Income',
        multiline: false
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q10',
        label: 'Education (schools and degrees)',
        multiline: false
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q11',
        text: [
          //this text is formatted and can even take HTML
          {
            html: '<strong>What are their priorities & interests?</strong>',

            style: 'text'
          },
          'Identity and Worldview'
        ],
        label: 'Affiliations (groups, clubs, associations, etc.)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q12',
        label: 'Important issues to them',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q13',
        label: 'Spiritual/religious association',
        multiline: false
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q14',
        label: 'Political association',
        multiline: false
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q15',
        label: 'Personal mantra',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q16',
        label: 'Intrinsic motivators',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q17',
        text: [
        'How they spend their time'
      ],
        label: 'Favorite books and authors',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q18',
        label: 'Events & conferences they attend',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q19',
        label: 'Periodicals & subscriptions',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q20',
        label: 'Gurus or thought leaders they follow',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q21',
        label: 'Hobbies they enjoy',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q22',
        label: 'Where do they spend time physically (IRL)?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q23',
        label: 'Where do they spend time online?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q24',
        text: [
          //this text is formatted and can even take HTML
          {
            html: '<strong>Problems, wants, desires, and challenges</strong>',

            style: 'text'
          },
          '(...as they relate directly or indirectly to the area of your business/offer)'
        ],
        label: 'What are their goals ?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q25',
        label: 'What are their challenges?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q26',
        label: 'What are the symptoms they are concerned about?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q27',
        label: 'How do they see themselves in terms of knowledge, confidence, comfort, ability related to the subject?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q28',
        label: 'How would they view you / your business / your industry as it relates to the subject? (positive or negative)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q29',
        text: [
          {
            html: '<strong>Moving toward addressing the problem</strong>',

            style: 'text'
          }
        ],
        label: 'What event(s) might trigger their decision to address the problem?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q30',
        label: 'What’s their role in deciding to work with you?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q31',
        label: 'What possible resistance might you get from them about your solution?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q32',
        text: [
          {
            html: '<strong>Personification</strong>',

            style: 'text'
          }
        ],
        label: 'What (if any) real world examples can you think of who are just like the avatar you’re creating?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q33',
        text: [
          {
            html: '<strong>Projecting into the future</strong>',

            style: 'text'
          }
        ],
        label: 'What (specific) value do you bring to your avatar as it relates to their problem?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q34',
        text: [
        'As it relates to your solution…'
        ],
        label: 'What can it help them achieve?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q35',
        label: 'What can it help them overcome?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q36',
        label: 'What can it help them avoid?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q37',
        label: 'What objections might they have about your solution?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q38',
        label: 'What other options might they be considering?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q39',
        text: [
          {
            html: '<strong>Perspective evolution</strong>',

            style: 'text'
          },
        'What changes occur in your avatar\'s life and perspective as it relates directly or indirectly to their problem and to your solution from prior to you solution to after they have encountered and implemented your solution?'
        ],

        label: 'Physical changes',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q40',
        label: 'Physical changes',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q41',
        label: 'Emotional changes',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q42',
        label: 'Changes in their beliefs',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q43',
        label: 'Changes in their status (both self perception and others\' perception)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q44',
        label: 'Changes in their daily life or routine',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-6-q45',
        label: 'Based on the above, does the unique selling proposition you created earlier effectively work for your ideal client avatar? If not, what changes would you make?',
        multiline: true
      }
    ),
  ]
}
