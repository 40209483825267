<div
  @simpleDissolve
  class='app-user-login-box'
>

  <p>
    Submit your email address to receive password reset instructions.
  </p>

  <mat-form-field>
    <mat-label>Email Address</mat-label>
    <input
      matInput
      type="text"
      [(ngModel)]="userEmail"
    >
  </mat-form-field>

  <button
    mat-raised-button
    color='accent'
    (click)='resetPassword()'
  >
    Send Instructions
  </button>

  <!-- links under the send email button -->

  <a
    href='#'
    class='forgot-password'
    (click)='exit()'
  >
    Go back
  </a>

</div>