import { TpgClientCollectionComponent } from './tpg-client-collection/tpg-client-collection.component';

/**
 * @Author: bnockles
 * @Date:   2021-03-05T22:58:00+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-13T21:35:20+09:00
 */



import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';
import { TpgLandingComponent } from './tpg-landing/tpg-landing.component';
import { TpgFormViewComponent } from '../tpg-forms/tpg-form-view/tpg-form-view.component';
import { UserCollectionComponent } from '../users/user-collection/user-collection.component';
import { TpgUserManagementComponent } from './tpg-user-management/tpg-user-management.component';
import { TpgUserComponent } from './tpg-user/tpg-user.component';
import { TpgClientComponent } from './tpg-client/tpg-client.component';

export const TPG_ROUTES: Routes = [
  {
    path: 'home',
    canActivate: [AngularFireAuthGuard],
    component: TpgLandingComponent,
  },
  {
    path: 'form-view',
    canActivate: [AngularFireAuthGuard],
    component: TpgFormViewComponent,
  },
  {
    path: 'user-management',
    canActivate: [AngularFireAuthGuard],
    component: TpgUserManagementComponent,
  },
  {
    path: 'client-management',
    canActivate: [AngularFireAuthGuard],
    component: TpgClientCollectionComponent,
  },
  {
    path: 'edit-client',
    canActivate: [AngularFireAuthGuard],
    component: TpgClientComponent,
  },
  {
    path: 'edit-user',
    canActivate: [AngularFireAuthGuard],
    component: TpgUserComponent,
  },


];
