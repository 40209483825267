<ng-container *ngIf='!appUser else signedIn'>
  <div class='login-box'>
    <mat-card>
      <mat-card-title>
        <app-logo>

        </app-logo>

      </mat-card-title>
      <div class='tagline accent'>
        {{ loginTagline }}
      </div>
      <mat-card-content>
        <div
          *ngIf="!forgotPassword"
          @simpleDissolve
          class='app-user-login-box'
        >
          <div class="">
            <mat-form-field>
              <mat-label>Email</mat-label>
              <input
                matInput
                type="text"
                [(ngModel)]="userEmail"
              >
            </mat-form-field>
          </div>
          <div class="">
            <mat-form-field>
              <mat-label>Password</mat-label>
              <input
                matInput
                type="password"
                [(ngModel)]="password"
              >
            </mat-form-field>
          </div>
          <button
            mat-raised-button
            color='accent'
            (click)='emailLogin()'
          >
            Sign In
          </button>

          <!-- the forgot password link/button -->
          <a
            class='forgot-password'
            href='#'
            (click)='forgotPassword = !forgotPassword'
          >Forgot Password
          </a>






        </div>
        <app-forgot-password
          *ngIf='forgotPassword'
          (onExit)='forgotPassword = !forgotPassword'
        >

        </app-forgot-password>

        <div class="action-row align-center">
          <button
            mat-raised-button
            color='primary'
            (click)='loginGoogle()'
          >
            Sign In with Google
          </button>
        </div>

      </mat-card-content>

    </mat-card>
  </div>


</ng-container>
<ng-template #signedIn>
  You are already signed in. You should be automatically redirected shortly.
</ng-template>