<div
  *ngIf='expositoryText'
  appTextElement
  [textContent]='expositoryText'
>

</div>

<div
  *ngIf='viewAsCheckboxes'
  class="form-group"
>
  <label
    class="control-label"
    [ngClass]='labelClass'
  >
    {{label}}
    <i
      *ngIf='helpText'
      [placement]='"top"'
      [popoverTitle]="'About'"
      [ngbPopover]="helpText"
      class="fas fa-question-circle"
    >
    </i>
  </label>
  <ul>
    <li *ngFor="let option of options">
      <mat-checkbox
        [checked]="isSelected(option.id)"
        (change)='toggle(option.id)'
      >
        {{option.description}}
      </mat-checkbox>
    </li>
  </ul>
</div>


<div
  *ngIf='!viewAsCheckboxes'
  class="form-group"
>
  <label
    class="control-label"
    [ngClass]='labelClass'
  >
    {{label}}
    <i
      *ngIf='helpText'
      [placement]='"top"'
      [popoverTitle]="'About'"
      [ngbPopover]="helpText"
      class="fas fa-question-circle"
    >
    </i>
  </label>
  <angular2-multiselect
    name="option-select"
    ngDefaultControl
    [data]="options"
    [(ngModel)]='selectedOptions'
    [settings]="{
    singleSelection: true,
    lazyLoading: true,
    primaryKey: 'id',
    labelKey: 'description',
    text:'Select one...',
    enableSearchFilter: false
  }"
  >
  </angular2-multiselect>
</div>