/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-09T10:00:25+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const PARTNERS: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '6_1',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Strategic Partnerships',

  text: [
    {
      html: 'STRATEGIC PARTNERSHIPS',

      style: 'heading'
    },
    'Strategic partnerships can be defined as just about any relationship you form with another professional or organization for mutual benefit. However, new business rarely comes from informal partnerships. That is why it’s important to formulate a detailed approach to your collaboration.'
  ],
  questions: [
    new FormTextControl(
      {
        uniqueID: '6-1-q1',
        label: 'Who would make an ideal strategic partner for you?',
        helpText: '(Example for Financial Advisors: CPAs, Estate Planning Attorneys, Insurance Agents, Title Companies, Business Consultants and Coaches, M&A Advisors, Business Brokers, Investment Bankers, Realtors, Mortgage Brokers)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '6-1-q2',
        label: 'In what ways can you collaborate?',
        helpText: '(Offer joint sessions with prospects, co-teach a workshop, offer your clients access to a free session with your strategic partner)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '6-1-q3',
        label: 'Who can you approach in the next two weeks about developing a strategic partnership?',
        multiline: true
      }
    ),
  ]
}
