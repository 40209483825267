/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-18T09:30:35+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const EMAIL: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '5_5',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Email Ideas',

  text: [
    {
      html: 'EMAIL MARKETING IDEAS CHEAT SHEET',

      style: 'heading'
    },

    '• The Prospect Centered Mailing, which focuses on sharing something interesting (a recent discovery, a cool opportunity, a big moment) of interest to your ideal prospect.',

    '• The News-Centered Mailing, which rides the coattails of a current news story or event.',

    '• The Greatest Hits Mailing, which is where you point your readers to some of your best content or curated content.',

    '• The Parable or Story Mailing, which shares a story that can be used as a teaching moment or a valuable lesson',

    '• The What I Use Mailing. This mailing allows you to educate your readers while sharing something about you professionally.',

    '• The Family Story Mailing. This mailing allows you to give your readers insight into you as a person.',

    '• The Contributor Mailing. This one works great to build solid relationships with your readers.',

    '• The "Can You Help Me Out?" Mailing, which gives you the opportunity to float an idea in front of your readers to see if there’s feedback or interest (ask them to reply or take a survey).',

    {
      html: '<a href="https://baeronmarketing.com/never-run-out-of-email-marketing-ideas-again/">Find more ideas here</strong>',

      style: 'text'
    },

    'IMPORTANT: Always tie your story back to a call-to-action'
  ],
  questions: []
}
