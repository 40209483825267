import { FooterComponent } from './footer/footer.component';
import { LogoComponent } from './logo/logo.component';
/**
 * @Author: bnockles
 * @Date:   2021-03-13T14:23:41+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-18T11:27:46+09:00
 */



import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';



@NgModule({
  declarations: [
    LogoComponent,
    FooterComponent,
  ],
  exports: [
    LogoComponent,
    FooterComponent,
  ],
  imports: [
    CommonModule,
    MatDividerModule,

  ]
})
export class BaseModule { }
