/**
 * @Author: bnockles
 * @Date:   2021-03-05T23:01:04+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-10T23:56:57+09:00
 */



import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tpg-form-view',
  templateUrl: './tpg-form-view.component.html',
  styleUrls: ['./tpg-form-view.component.scss']
})
export class TpgFormViewComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  back() {
    this.router.navigateByUrl('home');
  }

}
