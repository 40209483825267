/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-09T10:00:25+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const PRESENTATION: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '4_2',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Offer Presentation',

  text: [
    {
      html: 'PRESENTING YOUR OFFER',

      style: 'heading'
    },
    'When, where, and how your prospect encounters your offer will have a huge impact on how likely they are to say "yes."',

    'Sales conversions nearly always fail because prospects don’t believe in the solution, the solution provider, or worst, in themselves.'

  ],
  questions: [
    new FormTextControl(
      {
        uniqueID: '4-2-q1',
        label: 'What must your prospect believe before being presented with your offer?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '4-2-q2',
        label: 'What collateral is used to present your offer?',
        helpText: '(Physical sales letter, online sales page, video sales letter, brochure, slide deck, etc.)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '4-2-q3',
        label: 'What else needs to be created to support your offer?',
        helpText: '(email campaign, online ads, other web pages, videos, brochure, slide deck, etc.)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '4-2-q4',
        label: 'How do you ask for the sale?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '4-2-q5',
        label: 'What are your next steps after obtaining an agreement in principle?',
        multiline: true
      }
    ),
  ],
}
