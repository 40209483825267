/**
 * @Author: bnockles
 * @Date:   2021-03-04T21:56:42+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-14T20:42:24+09:00
 */



import { Component, OnInit, forwardRef, Input, EventEmitter, Output } from '@angular/core';
import { FormComponent } from '../form-component/form-component.component';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ValidatorFn, AbstractControlOptions } from '@angular/forms';
import { FormQuestion, FormQuestionOptions } from '../form-question';

interface FormTextOptions extends FormQuestionOptions {
  multiline: boolean;
}

export class FormTextControl extends FormQuestion {


  multiline: boolean;

  constructor(options: FormTextOptions, formState?: any, validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions) {
    super(options, formState, validatorOrOpts);
    this.formComponent = 'text';
    this.uniqueID = options.uniqueID;
    this.multiline = options.multiline;
  }

  // ngOnInit() {
  //
  // }


}

@Component({
  selector: 'app-form-text',
  templateUrl: './form-text.component.html',
  styleUrls: ['./form-text.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      //formwardRef is needed as our class will not be defined yet when the provider is registered and we need to tell the Provider constructor about it so it can wait for the class to be defined.
      useExisting: forwardRef(() => FormTextComponent),
      multi: true,
    },
  ],
})
export class FormTextComponent extends FormComponent implements ControlValueAccessor {

  private _val: string; // value of the component
  @Input() multiline: boolean = false;
  @Output() onEnter = new EventEmitter<void>();

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  /*************************************************
  ControlValueAccessor methods
  ****************************************/
  set value(val) {
    this._val = val;
    this.onChange(val);
    this.onTouch(val);
  }

  get value(): string {
    return this._val;
  }

  // programmatically writing the value
  writeValue(value: any) {
    this.value = value;
  }

  keydown(event: KeyboardEvent) {

    if (event.key == 'Enter') {
      event.preventDefault();
      this.onEnter.emit();
    }
  }

}
