<table
  mat-table
  [dataSource]="userQueryResults"
  class="mat-elevation-z8"
>

  <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

  <ng-container
    *ngFor='let columnID of displayedColumns'
    [matColumnDef]="columnID"
  >
    <th
      class="order-header"
      (click)="orderByColumn(columnID)"
      mat-header-cell
      *matHeaderCellDef
    > {{ columnNames[columnID].description }}
      <mat-icon class="order-arrow"  *ngIf="order!==null&&order.column===columnID&&order.orderType==='asc'">keyboard_arrow_up</mat-icon>
      <mat-icon class="order-arrow"  *ngIf="order!==null&&order.column===columnID&&order.orderType==='desc'">keyboard_arrow_down</mat-icon>
       </th>
    <td
      mat-cell
      *matCellDef="let element"
    >
      <ng-container *ngIf='columnNames[columnID].type == "date"'>
        <div *ngIf="!isNullDate(element[columnID])">
        {{ element[columnID] | date }}
        </div>

      </ng-container>
      <ng-container *ngIf='columnNames[columnID].type == "text"'>
        {{ element[columnID] }}
      </ng-container>
      <ng-container *ngIf='columnNames[columnID].type == "email"'>
        <a [href]='"mailto:"+element[columnID]'>
          {{ element[columnID] }}
        </a>
      </ng-container>



    </td>
  </ng-container>

  <tr
    mat-header-row
    *matHeaderRowDef="displayedColumns"
  ></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns;"
    (click)='onUserClick(row)'
  ></tr>
</table>
