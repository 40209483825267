/**
 * @Author: bnockles
 * @Date:   2021-03-03T22:33:27+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-13T23:02:58+09:00
 */



import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullViewDirective, SubtleDirective } from './styles.directive';
import { TopBarDirective, TopBarBodyDirective, NavLink } from './top-bar.directive';
import { HoverCursorDirective } from './hover-cursor.directive';
import { HeaderDirective } from './header.directive';
import { TextElementDirective } from './text-element.directive';



@NgModule({
  declarations: [
    FullViewDirective,
    TopBarDirective,
    TopBarBodyDirective,
    NavLink,
    HoverCursorDirective,
    HeaderDirective,
    TextElementDirective,
    SubtleDirective
  ],
  exports: [
    FullViewDirective,
    TopBarDirective,
    TopBarBodyDirective,
    NavLink,
    HeaderDirective,
    TextElementDirective,
    SubtleDirective
  ],
  imports: [
    CommonModule
  ]
})
export class StylesModule { }
