/**
 * @Author: bnockles
 * @Date:   2021-03-03T18:06:26+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-03T21:43:04+09:00
 */



import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertComponent } from './alert/alert.component';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ConfirmComponent } from './confirm/confirm.component'


@NgModule({
  declarations: [AlertComponent, ConfirmComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
  ]
})
export class AlertModule { }
