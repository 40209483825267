/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-10T22:27:34+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const NEEDSASSESSMENT: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '1_4',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Needs Assessment',

  /**
  questions. This is where you will do most of your work. Questions define what the user will see AND how they need to  answer. YOu can use text or multiple choice questions.
  */
  questions: [
    /**
    This first question might be most common for you. It is for short answers, such as "what is your name?"
    */
    new FormTextControl(
      {
        uniqueID: '1-4-q1',
        label: "Attract Traffic",
        helpText: 'What are you doing online, offline and via partnerships?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-4-q2',
        label: "Capture Leads",
        helpText: 'What tools are in place to capture information from prospects (and categorize their interest area)?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-4-q3',
        label: "Nurture Prospects",
        helpText: 'What systems are in place to educate, build trust, and move prospects towards a transaction? (Short-term vs. long term)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-4-q4',
        label: "Convert Sales",
        helpText: 'What is the structure of your sales process? Do you have systems or tools in place to support your selling?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-4-q5',
        label: "Transition With Easy",
        helpText: 'What barriers exist that prevent or slow down transactions? What opportunities exist to make things easier?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-4-q6',
        label: "Surprise, Delight, & Wow",
        helpText: 'What value are you providing your clients that furthers your relationship and turns them into raving fans?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-4-q7',
        label: "Upsell Clients",
        helpText: 'What other offers / products / services do you provide? Is there a system for presenting them to current clients?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-4-q8',
        label: "Get Referrals",
        helpText: 'What (if anything) are you doing to get referrals (from clients or strategic partners)?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-4-q9',
        label: "Database / CRM",
        helpText: 'How are you managing prospect & client data, updating it based on their actions, and leveraging this information in your marketing?',
        multiline: true
      }
    ),

    new FormTextControl(
      {
        uniqueID: '1-4-q10',
        label: "What are you currently using as a CRM?",
        helpText: '(Spreadsheet, proprietary database software, sales management tool, etc.)',
        text: [
          //this text is formatted and can even take HTML
          {
            html: 'CLIENT RELATIONSHIP MANAGER (CRM)',

            style: 'heading'
          },
        ],
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-4-q11',
        label: "How do you use your CRM to manage your sales pipeline?",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-4-q12',
        label: "What (if any) marketing automation features can be managed by your CRM?",
        helpText: '(Email marketing, SMS, internal task notifications, etc.)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-4-q13',
        label: "If applicable, what marketing automation features are you currently using?",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-4-q14',
        label: "What capabilities are currently in place to segment your list(s)?",
        helpText: '(Manual or action-based tagging, sales cycle stage tracking, etc.)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-4-q15',
        label: "What personalization is currently in place?",
        helpText: '(Event-based email campaign, Status-based communications, etc.)',
        multiline: true
      }
    )
  ]
}
