/**
 * @Author: bnockles
 * @Description - Like the completeFormService but whereas CompleteFormService allows you to view forms completed by the acting user, FormReviewService allows you to query all completed forms, even those that belong to other users
 * @Date:   2021-03-12T23:02:03+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-14T20:45:16+09:00
 */



import { Injectable } from '@angular/core';
import { ReplaySubject, Observable } from 'rxjs';
import { CompletedForm } from '../forms/form.service';
import { QueryFn, DocumentData, AngularFirestore } from '@angular/fire/firestore';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TpgFormReviewService {

  formQuery$ = new ReplaySubject<QueryFn<DocumentData>>();
  formQueryResults$ = new Observable<CompletedForm[]>();

  formTarget$ = new ReplaySubject<CompletedForm>();


  constructor(private afs: AngularFirestore) {

    //whenever the query changes, update the results
    this.formQueryResults$ = this.formQuery$.pipe(
      switchMap(
        queryFn => {
          return this.afs.collection<CompletedForm>('completedForms', queryFn).valueChanges({ idField: 'id' })
        }
      )

    )
  }

  /**
  queries for users whose author is
  @param userID - the author whose forms we want to query
  */
  queryFormsBy(userID: string) {
    this.formQuery$.next(ref => ref.where('canWrite', 'array-contains', userID));

  }

  queryFormsByField(value: string, field: string) {
    return this.afs.collection('users', ref => ref.where(field, '==', value)).get().toPromise().then(results => {
      let ids = [];
      results.forEach(userRef => {
        ids.push(userRef.id)
      })
      if (ids.length < 1) {
        throw new Error('No users found with that email address.')
      }
      return this.formQuery$.next(ref => ref.where('originator', 'in', ids));
    })


  }

}
