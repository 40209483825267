<ng-container *ngIf='expositoryText'>
  <div
    *ngFor='let textElement of expositoryText'
    appTextElement
    [textContent]='textElement'
  >

  </div>
</ng-container>



<div class="form-group">
  <!-- <label
    class="control-label"
    [ngClass]='labelClass'
  >
    {{label}}
    <i
      *ngIf='helpText'
      [placement]='"top"'
      [popoverTitle]="'About'"
      [ngbPopover]="helpText"
      class="fas fa-question-circle"
    >
    </i>
  </label> -->
  <mat-form-field>
    <mat-label>{{ label }}</mat-label>
    <input
      *ngIf='!multiline'
      matInput
      type="text"
      (keydown)='keydown($event)'
      [(ngModel)]="value"
      [matTooltip]="helpText"
      #tooltip='matTooltip'
      matTooltipPosition='below'
    >
    <textarea
      *ngIf='multiline'
      rows='6'
      matInput
      [(ngModel)]="value"
    ></textarea>
    <button
      mat-button
      *ngIf="value"
      matSuffix
      mat-icon-button
      aria-label="Clear"
      (click)="value=''"
    >
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>


</div>