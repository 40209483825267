/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-11T10:33:54+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const TARGETMARKET: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '2_2',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Target Market',

  text: [
    {
      html: 'TARGET MARKET',

      style: 'heading'
    },


    {
      html: '<strong>Business Growth Challenges</strong>',

      style: 'text'
    },

    'Most businesses waste far too much time, effort, and money trying to attract anyone and everyone to their products and services. According to a study in 2007, the market research firm Yankelovich estimated that the average person saw up to 5,000 ads per day. Imagine what that number has grown to today. How can any one business possibly stand out in such a noisy world?',

    'The answer lies in targeting your message to those most likely to have interest and take action. There are various ways to define a target market, including, demographic, geographic, and/or affinity.'
  ],
  questions: [
    new FormTextControl(
      {
        uniqueID: '2-2-q1',
        label: 'Who are your ideal customers? What makes them “ideal?”',
        helpText: '(Start with basic demographics like age, income, profession, but be sure to include further psychographic and behavioral details as well.)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-2-q2',
        label: "What are the biggest problems they’re trying to overcome?",
        helpText: '(What keeps them up at night? Be specific, not general.)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-2-q3',
        label: 'What fears does your target customer have about addressing their pain (regardless of the solution they select)?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-2-q4',
        label: "Why should they do business with you (as opposed to someone else)?",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-2-q5',
        label: 'Before committing to working with you, how (specifically) would your prospect define “success?”',
        helpText: '(What outcome would they be excited to have achieved?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-2-q6',
        label: 'Who is NOT your target client? Why not?',
        helpText: '(Start with basic demographics like age, income, profession, but be sure to include further psychographic and behavioral details as well.)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '2-2-q7',
        label: "What are their most common objections to doing business with you? Why do they feel that way?",
        helpText: '(Cost, time commitment, time to achieve success, etc.)',
        multiline: true
      }
    ),
  ]
}
