/**
 * @Author: bnockles
 * @Date:   2021-01-06T10:20:29+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-13T15:09:37+09:00
 */



import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-form-component',
  templateUrl: './form-component.component.html',
  styleUrls: ['./form-component.component.scss']
})
export class FormComponent implements OnInit {

  @Input() labelClass: string = '';
  @Input() label: string;
  @Input() helpText?: string;
  @Input() expositoryText: string;


  /*************************************************
  ControlValueAccessor fields
  ****************************************/
  onChange: any = () => { };
  onTouch: any = () => { };

  constructor() { }

  ngOnInit(): void {
  }

  /*************************************************
  ControlValueAccessor methods (used by children)
  ****************************************/
  // method to be triggered on UI change
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  // method to be triggered on component touch
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

}
