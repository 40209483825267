/**
 * @Author: bnockles
 * @Date:   2021-03-05T21:56:05+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-18T11:02:32+09:00
 */



import { Component, OnInit } from '@angular/core';
import { TPG_FORM_LIBRARY } from '../tpg-form-library';
import { FormModel } from 'src/app/forms/form-model';
import { FormService, CompletedForm } from 'src/app/forms/form.service';
import { Router } from '@angular/router';
import { CompletedFormsComponent } from '../completed-forms/completed-forms.component';

@Component({
  selector: 'app-form-library',
  templateUrl: './form-library.component.html',
  styleUrls: ['./form-library.component.scss']
})
export class FormLibraryComponent extends CompletedFormsComponent {

  constructor(protected formService: FormService, protected router: Router) {
    super(formService, router);
  }

  ngOnInit(): void {
    //do not remove super.ngOnItint as it handles getting completed form information
    super.ngOnInit();
  }

  isStarted(formID: string): boolean {
    return formID in this.formToAnswerIndexMap;
  }

  completedForm(formID): CompletedForm {
    return this._completedFormQueryResult[this.formToAnswerIndexMap[formID]];
  }

  /**********************************************
  public methods
  *********************************************/
  openForm(form: FormModel) {
    //starts a new form
    if (this.isStarted(form.id)) {
      this.editForm(this.completedForm(form.id));
      this.router.navigateByUrl('form-view');
    } else {
      this.formService.startForm(form);
    }


  }

  print(form: FormModel) {
    if (this.isStarted(form.id)) {
      this.editForm(this.completedForm(form.id));
      this.router.navigateByUrl('form-view?print=true');
    }
  }
}
