<mat-list>
  <ng-container *ngFor='let section of formLibrary'>
    <div mat-subheader>
      <h3>{{ section.header }}</h3>
    </div>
    <mat-list-item
      appHoverCursor
      *ngFor="let form of section.forms | keyvalue"
    >
      <mat-icon mat-list-icon>feed</mat-icon>
      <div mat-line (click)=openForm(form.value)>
        {{form.value.title}}
        <span
          *ngIf='isStarted(form.key)'
          class='accent'
        >
          (Started)
        </span>
      </div>
      <div
        *ngIf='form.value.questions && !isStarted(form.key)'
        mat-line (click)=openForm(form.value)
      >
        {{form.value.questions.length | plural  : 'question'}}
      </div>
      <!--
      Data for completed forms
      -->
      <div
        *ngIf='isStarted(form.key)'
        mat-line (click)=openForm(form.value)
      > Last opened: {{completedForm(form.key).lastEdited | date}}</div>
      <div
        *ngIf='isStarted(form.key)'
        mat-line (click)=openForm(form.value)
      > {{completedForm(form.key).completedAnswers}}/{{completedForm(form.key).totalQuestions | plural  : 'question'}}
        completed
      </div>
      <div (click)="print(form.value)" *ngIf="isStarted(form.key)" style="cursor:pointer">
        <mat-icon mat-list-icon>print</mat-icon>
      </div>
    </mat-list-item>
    <mat-divider></mat-divider>
  </ng-container>


</mat-list>
