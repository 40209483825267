/**
 * @Author: bnockles
 * @Date:   2021-03-18T11:08:43+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-18T11:38:04+09:00
 */



import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['../login/login.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  userEmail: string;
  @Output() onExit = new EventEmitter<void>();

  constructor(private as: AlertService, private auth: AngularFireAuth) { }

  ngOnInit(): void {
  }

  resetPassword() {
    let alertService = this.as;
    this.auth.sendPasswordResetEmail(this.userEmail).then(_ => {
      this.exit();

      this.as.success('Please check your inbox.', 'Email sent')
    }).catch(function(error) {
      if (error.code = 'auth.invalid-email') {
        alertService.error('This email address was not found. Did you make a typo?')
      } else {
        alertService.error('Could not send a password reset email. Please try again.')
      }
      console.error(error.code);

    })
  }

  exit() {
    this.onExit.emit();
  }

}
