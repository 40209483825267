/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-18T09:30:45+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const REFERRAL: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '6_3',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Referrals',

  text: [
    {
      html: 'LEVERAGE REFERRALS TO GROW',

      style: 'heading'
    },

    'Having clients referred to your business can be incredibly powerful, especially when those referrals come from your current clients. Statistics show that referred prospects are not only easier to convert, but once they become clients, they have greater loyalty to you and your business.',

    'The word "referral" is broadly used, and can mean a few different things. For example, having a client provide you with someone’s name (and, hopefully, contact information) can be categorized as a referral. Of course, having your client make an introduction, or personally recommend your business or service can be even more powerful.',

    'This activity is designed with the goal of "orchestrating" referrals, rather than allowing them to happen sporadically or opportunistically.'
  ],
  questions: [
    new FormTextControl(
      {
        uniqueID: '6-3-q1',
        label: 'How do you know when it is appropriate to make a referral request from a current client?',
        helpText: '(How/when do you know if they are willing, able, and can provide a meaningful referral?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '6-3-q2',
        label: 'What type of individual/institution would be a good referral for you?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '6-3-q3',
        label: 'By what means do you describe your ideal referral to your current client?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '6-3-q4',
        label: 'How often and via what means do you ask clients/customers for referrals?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '6-3-q5',
        label: 'HWhat is the process you use to follow up on a referral (with both the client and their referral)?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '6-3-q6',
        label: 'How can you regularly demonstrate to clients that you have a "culture of referrals" in your business?',
        multiline: true
      }
    ),
  ],
  conclusionText: [
    {
      html: 'Referral ideas...',

      style: 'heading'
    },

    '• Ask a client for an introduction to 3 people who are just like them*',

    '• Plan targeted events for clients to bring a guest to attend with them - events don’t have to be related to your business (in fact they shouldn’t be). Host a picnic, a “big game” viewing on the TV in your conference room, rent out a movie theater for a private screening, host a “spa day” for clients and their guests.',

    '• Take your best clients out to celebrate their birthday along with two of their friends (leave early so they’ll talk about you)',

    '• Create a client “club” and ask members to invite guests',

    '• Incentivize referrals with discounted or free service to your client',

    '• Offer monthly client education events and ask clients to bring guests',

    '• Include “free” events as part of a paid package, and have client bring along an “accountability partner” who is not currently a client',

    '• Create a letter, video, or other sharable medium for clients to use as a way to introduce you',

    '• Take your best clients out for breakfast or lunch and ask them a) about how they achieved success in their business or life, b) for advice on how to improve your business, and c) explain how much you value them and ask if there are others in their circles to whom they could make an introduction*',

    '• Require 2 referrals as a condition of working with you',

    '• Present clients with a list of individuals you’d like to target, asking if they know anyone on the list. Ask for permission to use their name when reaching out to those people.',

    '• Incentivize referrals with a “free lunch” reward when 4 contacts have been supplied.',

    '• Incentivize referrals with a donation to a charity reward when 4 contacts have been supplied',

    '• Create a monthly or bi-monthly “special report” that clients and partners can share with people they know',

    '* HOW TO FRAME YOUR REQUEST: "You’re my ideal client. If I could have 50 more just like you, I’d be the happiest person in the world. So, I want to pick your brain to help me. There are two things I’d like to discuss with you: first, what it is about my business that initially attracted you, keeps you here, and is most important to you? And then… if you were in my shoes, and wanted to mount a concentrated effort to find and attract more clients like you, how would you go about it?"'
  ],
}
