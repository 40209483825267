/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-14T21:02:23+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const TRACTION: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '3_4',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Traction',

  text: [
    //this text is formatted and can even take HTML
    {
      html: 'TRACTION CHANNELS',

      style: 'heading'
    },

    'There is no end to the platforms and channels you can use to market your business. However, just because you can market using these channels doesn\'t mean you should.',

    'In this activity, you must select three (and only three) channels to focus on, which are the most likely channels for your ideal clients to find you.',

    'Select from the following:',
    'Search Engine Marketing (SEM)',
    'Social and Display Ads',
    'Search Engine Optimization (SEO)',
    'Broacast Media Buys',
    'Content Marketing',
    'Email Marketing',
    'Existing Platforms',
    'Viral Marketing',
    'Targeting Blogs',
    'Trade Shows',
    'Events',
    'Sales',
    'Business Development / Strategic Partnerships',
    'Offline Ads',
    'Direct Mail',
    'Public Relations',
    'Unconventional PR',
    'Engineering as Marketing',
    'Affiliate Programs',
    'Speaking Engagements'

  ],
  questions: [
    /**
    This first question might be most common for you. It is for short answers, such as "what is your name?"
    */
    new FormTextControl(
      {
        uniqueID: '3-4-q1',
        label: 'Traction Channel #1',
        multiline: false
      }
    ),
    new FormTextControl(
      {
        uniqueID: '3-4-q2',
        label: 'Traction Channel #2',
        multiline: false
      }
    ),
    new FormTextControl(
      {
        uniqueID: '3-4-q3',
        label: 'Traction Channel #3',
        multiline: false
      }
    ),
  ]
}
