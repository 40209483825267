import { CustomPipesModule } from '../base/custom-pipes/custom-pipes.module';
import { AppFormsModule } from '../forms/app-forms.module';
/**
 * @Author: bnockles
 * @Date:   2021-03-05T21:58:58+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-14T20:08:05+09:00
 */



import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormLibraryComponent } from './form-library/form-library.component';
import { CompletedFormsComponent } from './completed-forms/completed-forms.component';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { TpgFormViewComponent } from './tpg-form-view/tpg-form-view.component';
import { MatButtonModule } from '@angular/material/button';
import { StylesModule } from '../base/styles/styles.module';
import { FormQueryComponent } from './form-query/form-query.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    FormLibraryComponent,
    CompletedFormsComponent,
    TpgFormViewComponent,
    FormQueryComponent,
  ],
  exports: [
    FormLibraryComponent,
    CompletedFormsComponent,
    FormQueryComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatListModule,
    MatDividerModule,
    MatIconModule,
    CustomPipesModule,
    AppFormsModule,
    StylesModule,
  ]
})
export class TpgFormsModule { }
