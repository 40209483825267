/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-09T10:00:25+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const CRM: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '3_3',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'CRM & Personalization',

  text: [
    //this text is formatted and can even take HTML
    {
      html: 'CRM PERSONALIZATION & SEGMENTATION',

      style: 'heading'
    },
    'Managing and tracking the data you collect from prospects is critical. The better your system, the more effectively you can control how, when, and where you communicate with them, in an effort to continually move them forward in your relationship.',

    'In this exercise, we’ll look at your current database management system, and consider what (if any) adjustments or improvements you might consider.'
  ],
  questions: [
    new FormTextControl(
      {
        uniqueID: '3-3-q1',
        label: 'What are the key characteristics of a contact on your list that impacts how you market to them?',
        helpText: '(Gender, industry, marital status, buyer vs. prospect)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '3-3-q2',
        label: 'What segmentations are you currently using or would you like to use?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '3-3-q3',
        label: 'What is the minimum segmentation you must have in place to better communicate with your contacts?',
        helpText: '(Prospect, stage in sales process, client status, referral partner status etc.)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '3-3-q4',
        label: 'What personalization is currently in place?',
        helpText: '(Event-based email campaign, Status-based communications, etc.)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '3-3-q5',
        label: 'What personalization should you consider implementing in the next 30 days?',
        multiline: true
      }
    ),
  ]
}
