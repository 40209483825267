/**
 * @Author: bnockles
 * @Date:   2021-03-04T23:24:54+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-10T23:48:03+09:00
 */


import {Unsubscriber} from '../../unsubscriber.component';
import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormService} from '../form.service';
import {FormModel} from '../form-model';
import {FormArray, FormGroup} from '@angular/forms';
import {combineLatest} from 'rxjs';
import {FormQuestion} from '../form-question';
import {KeyValue} from '@angular/common';
import {TemplateService} from 'src/app/template-variables/template.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-form-view',
  templateUrl: './form-view.component.html',
  styleUrls: ['./form-view.component.scss']
})
export class FormViewComponent extends Unsubscriber implements OnInit {

  @ViewChild('formView') formView: ElementRef;
  @Output() back: EventEmitter<any> = new EventEmitter();

  form: FormModel;
  // questionsArray = new FormArray([]);
  formGroup: FormGroup;
  private isPrinting = false;

  constructor(private formService: FormService, private templateService: TemplateService, private route: ActivatedRoute, private router: Router) {
    super();
    this.anotherSubscription = this.route.queryParams.subscribe(params => {
      this.isPrinting = params.print;
    });
  }

  ngOnInit(): void {
    this.anotherSubscription = this.formService.loadedFormTemplate$.subscribe(
      loadedFormTemplate => {
        this.formGroup = loadedFormTemplate.formGroup;
        this.form = loadedFormTemplate.formModel;
        console.log('form', this.form);
      }
    );
    if (this.isPrinting) {
      this.homePrint();
    }
  }

  homePrint(): void {
    setTimeout(() => {
      window.print();
      this.back.emit();
    }, 1000);
  }

  /************************************************
   accessors
   ************************************************/


  /************************************************
   public methods
   ************************************************/

    // Order by ascending property value
  sortOrderAscOrder = (a: KeyValue<number, FormQuestion>, b: KeyValue<number, FormQuestion>): number => {
    return a.value.sortIndex - b.value.sortIndex;
  }

  /**
   "transcribe" the valeus from the questions over to the answer sheet, then saves using the service
   */
  saveForm() {
    this.formService.saveForm().then(
      _ => {
        this.templateService.afterFormSave();
      }
    );
  }

  print() {
    window.print();
  }

  goBack() {
    this.back.emit();
  }

  generatePdf() {
    let topBar = document.getElementById('app-top-bar');
    topBar.style.position = 'relative';
    let element = document.querySelector("html");
    html2canvas(element).then(canvas => {
      const image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");  // here is the most important part because if you dont replace you will get a DOM 18 exception.
      console.log(image);
      const w = canvas.width / 2;
      const h = canvas.height / 2;
      console.log({
        w: w,
        h: h
      });

      let doc = new jsPDF('p', 'px', [w,h]);
      doc.addImage({
        imageData: image,
        x: 0,
        y: 0,
        width: w,
        height: h
      });
      doc.save('sample-file.pdf');
      topBar.style.position = 'fixed';
    });
  }
}
