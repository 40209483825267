/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-18T09:29:30+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const STRUCTURE: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '4_1',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Offer Structure',

  text: [
    {
      html: 'STRUCTURING YOUR OFFER',

      style: 'heading'
    },
    'There’s a huge difference between the product or service you sell and the offer you present to your buyer. Products and services are commodities. They have features and benefits - which are the two things your competition probably relies on to sell their products or services.',

    'But when you structure your offer correctly, you’re appealing to your customer’s desires - based on the thing that matters to them most. In other words, your offer is designed to bridge their problem or desire to your product or service.',

    'Your offer is also about how you can create an ideal future state for your prospect. This is either about the avoidance of suffering /pain or the gaining of pleasure. The following questions will help you bring clarity around what exactly it is your offering.'

  ],
  questions: [
    new FormTextControl(
      {
        uniqueID: '4-1-q1',
        label: 'What is your core product or service?',
        helpText: '(Describe this in terms of features and benefits)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '4-1-q2',
        label: 'What problem does it solve for your prospect?',
        helpText: '(Describe this in terms of the primary benefits and secondary benefits)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '4-1-q3',
        label: 'What promise does it make about your prospect’s future state?',
        helpText: '(How will your product/service help them achieve a goal or avoid a pain?)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '4-1-q4',
        label: 'How does the above relate to your prospect’s pain or desire?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '4-1-q5',
        label: 'What is your current "needs assessment" or "discovery" process, and how does it help you uncover your prospects most critical pain points?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '4-1-q6',
        label: 'What changes or additions do you need to make to your "needs assessment" or "discovery" process to better uncover your prospects most critical pain points?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '4-1-q7',
        label: 'What more can you add to the way you present your offer to make it a "no-brainer" decision for your prospect?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '4-1-q8',
        label: 'List the key components of your offer',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '4-1-q9',
        label: 'Does your offer reflect your mission, vision, and core values? If so, how?',
        multiline: true
      }
    ),
  ],
  conclusionText: [
    {
      html: 'Additional Questions To Consider...',

      style: 'heading'
    },

    '• Are the benefits of your offer something your prospect is willing to pay for?',
    '• How does this offer provide superior value to the customer? (Outcome, Price, Time, etc.)',
    '• What are the ordinary parts of your service that the average consumer doesn’t know about? How can you highlight these to create perceived value?',
    '• How can you package this to make it more appealing to your target audience?',
    '• Is there something unusual, unique, or proprietary about your service that can be highlighted?',
    '• Does your product, service, or package have a unique name? If not, create one.',
    '• Do you offer different tiers for consideration (good, better, best)?',
    '• Is there a scarcity or a deadline that can be applied to any or all of your offer?',

    {
      html: 'Most Common Types of Offers...',

      style: 'heading'
    },
    '• Hard offer - prospect pays, the product/service is delivered',
    '• Soft offer - product/service is delivered, payment is deferred',
    '• Negative option offer - forced continuity by featuring a premium',
    '• Installment offer - payment plan option',
    '• Charter offer - early or pre-launch discount',
    '• Exclusive offer - limited to a specific group of prospects or customers',
    '• Limited offer - legitimate scarcity offer (that can be explained)',
    '• Limited-time offer - tied to a deadline',
    '• One-time offer - they’ll only see the offer once (no brainer offer)',
    '• Qualified offer - requires an application or qualification',
    '• Combination of two or more of the above',

    {
      html: 'Typical Offer Components...',

      style: 'heading'
    },
    '• List of the deliverables - what they get (the more detailed the better)',
    '• Primary and secondary benefits - what each deliverable will do for them',
    '• Price(s) and terms - present price vs. "actual value," rationale for discount vs. "value," and then compare price to a non-associate expense "all of this for the price of dinner for two at a fancy French restaurant"',
    '• Premiums and/or bonuses - premiums are featured desirable elements they can only get when they buy the full offer and bonuses are “extras” (which can be items already included in the product, simply broke out as an additional item)',
    '• Risk reversal - often comes in the form of a guarantee (though it doesn’t need to be a “money back” guarantee), but can also be position risk as what do they risk by NOT proceeding',
    '• Reason to respond right now - scarcity, urgency, or at the very least so they can overcome the problem ASAP',
    '• Call-to-action - what to do next'
  ],
}
