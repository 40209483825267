/**
 * @Author: bnockles
 * @Date:   2021-03-03T18:07:48+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-03T21:45:21+09:00
 */



import { Component, OnInit, Input } from '@angular/core';
import { ConfirmComponent } from '../confirm/confirm.component';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  @Input() title: string;
  @Input() body: string;


  constructor(public dialogRef: MatDialogRef<AlertComponent>) { }

  ngOnInit(): void {
  }

}
