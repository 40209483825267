/**
 * @Author: bnockles
 * @Date:   2021-03-11T00:02:32+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-11T00:04:12+09:00
 */



import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TpgTabService {

  selectedTabIndex = 0;

  constructor() { }
}
