/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-18T09:29:40+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const SELLING: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '4_5',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Selling',

  text: [
    
    'Effective selling can neither be “off the cuff” nor can it be totally customized to each situation. The best way to guarantee predictability in your revenue is to be consistent in how each and every prospect is marketed and sold. In this exercise, we’ll explore your selling process.'
  ],
  questions: [
    new FormTextControl(
      {
        uniqueID: '4-5-q1',
        label: 'Is there a “system” for selling? If so, what is it?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '4-5-q2',
        label: 'What are the stages of your selling process?',
        helpText: '(New prospect, unqualified lead, first appointment, etc.)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '4-5-q3',
        label: 'How does your selling process correspond to your brand identity?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '4-5-q4',
        label: 'How does your selling process reflect the way your ideal client purchases?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '4-5-q5',
        label: 'Where are you losing revenue in your selling process?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '4-4-q6',
        label: 'What are the top 3 things you’d like to improve about your current selling system?',
        multiline: true
      }
    ),
  ],
  conclusionText: [
    {
      html: 'Additional Question To Consider...',

      style: 'heading'
    },

    '• Is your selling process documented?',

    '• How do you qualify leads and identify them as prospects worthy of having a sales conversation?',

    '• Do you monetize unqualified leads in any way? (Asking for referrals or otherwise)',

    '• What are the measurable pieces of your process?'
  ],
}
