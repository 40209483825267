import { AppFormsModule } from '../forms/app-forms.module';
/**
 * @Author: bnockles
 * @Date:   2021-03-11T19:01:00+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-13T22:16:31+09:00
 */



import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserViewComponent } from './user-view/user-view.component';
import { UserCollectionComponent } from './user-collection/user-collection.component';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    UserViewComponent,
    UserCollectionComponent,
  ],
  exports: [
    UserViewComponent,
    UserCollectionComponent
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTableModule,
    AppFormsModule,
    FormsModule,
    ReactiveFormsModule,
  ]
})
export class UserModule { }
