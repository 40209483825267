/**
 * @Author: bnockles
 * @Description: A file for things that need to change to repurpose the app.
 * @Date:   2021-03-06T21:33:29+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-14T19:52:45+09:00
 */



import { Injectable } from '@angular/core';
import { TPG_FORM_LIBRARY } from '../tpg-forms/tpg-form-library';
import { FormSection, FormModel } from '../forms/form-model';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FormGroupLayout } from '../base/base-classes';
import { AngularFirestore } from '@angular/fire/firestore';
import { AppUser } from '../base/services/backend/user.service';
import { AppClient } from '../tpg-app/tpg-client.service';
import { AlertService } from '../base/services/alert.service';

export interface TpgUser extends AppUser {
  clientID?: string;
  isAdmin?: boolean;
}


@Injectable({
  providedIn: 'root'
})
export class TemplateService {


  permissions: { [permissionID: string]: boolean } = {};

  constructor(private router: Router, private afs: AngularFirestore, private as: AlertService) { }

  /**
  When a user loads a CompletedForm, the source form must be looked up. That is done here.
  */
  get formLibrary(): FormSection[] {
    return TPG_FORM_LIBRARY;
  }

  get userEditForm(): FormGroupLayout {
    return {
      formLayout: {
        order: ['displayName', 'email', 'clientID'],
        data: {
          displayName: {
            label: 'Display name',
            formComponent: 'text'
          },
          email: {
            label: 'Email',
            formComponent: 'text'
          },
          clientID: {
            label: 'Client ID',
            helpText: 'The unique identifier for the client organization this user belongs to. (A client ID is required before the use can view forms)',
            formComponent: 'text'
          }
        }
      },
      formGroup: new FormGroup({
        displayName: new FormControl('', Validators.required),
        email: new FormControl('', Validators.required),
        clientID: new FormControl('')
      })
    }
  }

  afterFormSave() {
    this.router.navigateByUrl('home');
  }

  saveUser(formGroup: FormGroup, userID: string): Promise<void> {
    //check to see if the clientID is dirty:
    let userData = formGroup.value;
    if (!formGroup.controls.clientID.dirty) {
      delete userData.clientID
    }
    //id is not saved in the UserModel
    delete userData.id;
    //if the clientID is dirty, let's check to verify that it exists and only save if it does.
    var clientFound = false;
    if (userData.clientID) {
      //first we should make sure the user isn't already in an organizations. This is unlikely, but we don't want to give a useer write access to more than one Organization, nor showing up when we see the list of members in the organization view:
      return this.afs.collection<AppClient>('clients', ref => ref.where('members', 'array-contains', userID)).get().toPromise().then(result => {

        //remove the user from eveery organization (there should be only one at MOST, but – you know – "sanity checks" and all!)
        if (result.docs.length > 0) {
          var writes: Promise<void>[] = []
          for (let doc of result.docs) {
            //get the members, sans this user
            let oldMembers = doc.data().members;
            oldMembers = oldMembers.splice(oldMembers.indexOf(userID), 1);
            writes.push(doc.ref.update({ members: oldMembers }))
          }
          return Promise.all(writes);
        } else {
          return Promise.resolve(null);
        }
      }).then(_ => {
        //now let's check to see that the client that was selected actually exists
        return this.afs.doc<AppClient>('clients/' + userData.clientID).get().toPromise()
      }).then(result => {
        if (result.exists) {
          clientFound = true;
          let priorMembers = result.data().members;
          //check that  the member truly doesn't already exist
          if (!priorMembers) {
            priorMembers = [userID];
            return this.afs.doc('clients/' + userData.clientID).update({ members: priorMembers }).catch(console.error);
          } else if (priorMembers.indexOf(userID) == -1) {
            priorMembers.push(userID);
            return this.afs.doc('clients/' + userData.clientID).update({ members: priorMembers }).catch(console.error);
          } else {
            return Promise.resolve(null);
          }

        } else {
          return Promise.resolve(null);
        }
      }).then(_ => {
        if (clientFound) {
          return this.afs.doc('users/' + userID).update(userData).catch(console.error);
        } else {
          delete userData.clientID
          return this.afs.doc('users/' + userID).update(userData).catch(console.error);
        }
      }).then(_ => {
        if (!clientFound) {
          this.as.error('The clientID you entered does not match an existing client. You can find client ID in the Client view.')
        }
      }).catch(console.error)
    } else {
      return this.afs.doc('users/' + userID).update(userData).catch(console.error);
    }



  }

  updatePermissions(userData: AppUser) {
    const tpgUser = userData as TpgUser;
    this.permissions.isClient = !!tpgUser.clientID;
    this.permissions.isAdmin = tpgUser.isAdmin;
  }


}
