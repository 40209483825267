<div appFullView>
  <mat-card *ngIf='isClient else notClient'>
    <mat-card-title *ngIf='appUser'>
      Welcome, {{ appUser.displayName }}
    </mat-card-title>
    <mat-card-content>
      <mat-tab-group
        mat-align-tabs="start"
        [(selectedIndex)]='tpgTabService.selectedTabIndex'
      >

        <!---------------------------------------------------------
        Removed: to see a section displayiing only the user's completed forms, un-comment this section:

        <mat-tab label="Completed Forms">
          <app-completed-forms>

          </app-completed-forms>
        </mat-tab>

        ----------------------------------------------------------->


        <mat-tab label="Forms">


          <app-form-library>

          </app-form-library>

        </mat-tab>

        <mat-tab
          *ngIf='isAdmin'
          label="Form Query"
        >
          <app-form-query>

          </app-form-query>
        </mat-tab>
      </mat-tab-group>

    </mat-card-content>
  </mat-card>
  <ng-template #notClient>
    <mat-card>
      <mat-card-content>
        <div class='alert-box bg-accent'>
          At this moment, your user profile isn't registered as a client of {{ companyName }}. Please wait until an
          administrator has registered your account. After that time, forms will be made available to you.
        </div>
      </mat-card-content>
    </mat-card>
  </ng-template>


</div>
