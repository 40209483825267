import { TemplateService } from '../template-variables/template.service';
import { AutoSaveService, SaveOperation } from '../base/services/auto-save.service';
/**
 * @Author: bnockles
 * @Description: Hosts observable of open form and handles actions performed on the form, such as editing and deleting
 * @Date:   2021-03-05T22:03:59+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-18T10:37:18+09:00
 */



import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subscription, of } from 'rxjs';
import { FormModel } from './form-model';
import { AngularFirestore } from '@angular/fire/firestore';
import { UserService } from '../base/services/backend/user.service';
import { FormGroup, AbstractControl } from '@angular/forms';
import { take, switchMap } from 'rxjs/operators';

export type QueryType = 'owner';

/**
The model for  forms that are saved in the completedForms collection
*/
export interface CompletedForm {
  title?: string;//not saved to database, but dynamically added by copying from library at load
  completedAnswers?: number;//dynamically counted from number of non-trivial answers
  totalQuestions?: number;//not saved to database, but dynamically added by copying from library at load
  originator: string;//this is a bit redundant, but it's required, because queries can't use 'array-contains' since reads are not public. To access forms written by others, we must store completedFormIDs in the user's doc
  creationDate: number;
  lastEdited: number;
  id?: string;//use valueChanges({idField:"id"}) to get doc id when editing
  canWrite: string[];//an array of userIDs that have write access
  canRead: string[]//an array of userIDs that have read access
  formID: string;//the ID of the source form
  answers: {//maps questonIDs to ther answers
    [questionID: string]: any
  }
}

export interface FormTemplate {
  formModel: FormModel;
  formGroup: FormGroup;
}

@Injectable({
  providedIn: 'root'
})
export class FormService {

  private _answerSheetID: string;//when defined, an answer sheet is being edited, otherwise, this is a new answer sheet
  answerSheet$ = new ReplaySubject<CompletedForm>();
  private _answerSheet: CompletedForm;


  completedFormQueryResult$ = new ReplaySubject<CompletedForm[]>();
  private _completedFormQueryResult: CompletedForm[];

  selectedForm$ = new ReplaySubject<FormModel>();
  private _selectedForm: FormModel;

  /**
  When a form is loaded, this form group is popualted with answers that may already be filled
  */
  loadedFormTemplate$ = new ReplaySubject<FormTemplate>();
  private _loadedFormTemplate: FormTemplate;
  private _loadedFormGroup: FormGroup;

  constructor(private afs: AngularFirestore, private userService: UserService, private templateService: TemplateService, private autoSave: AutoSaveService) {
    this.userService.appUser$.pipe(
      switchMap(
        user => {
          if (user) {
            return this.afs.collection('completedForms', ref => ref.where('originator', '==', user.id)).valueChanges({ idField: 'id' });
          } else {
            return of(null);
          }

        }
      )


    ).subscribe(
      queryResult => {

        this._completedFormQueryResult = (queryResult as CompletedForm[]).sort((a: CompletedForm, b: CompletedForm) => {
          return b.lastEdited - a.lastEdited;
        })
        this.completedFormQueryResult$.next(this._completedFormQueryResult);
      },
      console.error
    )

  }


  /******************************************
    accessors
  *******************************************/

  set selectedForm(form: FormModel) {

    this._selectedForm = form;
    this.selectedForm$.next(form);

  }

  get completedFormQueryResult(): CompletedForm[] {
    return this._completedFormQueryResult;

  }

  get answerSheet(): CompletedForm {
    return this._answerSheet;

  }

  set answerSheet(answers: CompletedForm) {
    this._answerSheet = answers;
    this.answerSheet$.next(answers);

  }
  /******************************************
    private methods
  *******************************************/

  /**
  Makes a form group from the questions in the loaded form,
  @param loadedAnswers: - when non-trivial  , the FormControl's values are also set
  */
  private buildFormGroup(loadedAnswers?: CompletedForm) {
    const loadedFormGroupControls: { [key: string]: AbstractControl } = {}
    //iterate through the questions:
    for (let questionIndex in this._selectedForm.questions) {
      const question = this._selectedForm.questions[questionIndex];
      question.sortIndex = parseInt(questionIndex);
      loadedFormGroupControls[question.uniqueID] = question;
      if (loadedAnswers && loadedAnswers.answers[question.uniqueID]) {
        question.setValue(loadedAnswers.answers[question.uniqueID]);
      } else {
        question.setValue('');
      }
    }

    this._loadedFormGroup = new FormGroup(loadedFormGroupControls);
    this._loadedFormTemplate = {
      formModel: this._selectedForm,
      formGroup: this._loadedFormGroup
    }
    //create an autosave function:
    this.autoSave.startMonitoring(new SaveOperation(this._loadedFormGroup, 'completedForms/' + this._answerSheetID, 'answers'));

    this.loadedFormTemplate$.next(this._loadedFormTemplate);
  }



  /**
  Takes the values off the loadedFormGroup and puts them into a JSON object that can be saved to the database
  */
  private extractAnswers() {
    //iterate through the question keys in the FORM group:
    for (let question of this._selectedForm.questions) {
      this._answerSheet.answers[question.uniqueID] = question.value;

    }
  }


  /******************************************
    public methods
  *******************************************/
  /**
  When a form is started, an answersheet must be created:
  */
  startForm(form: FormModel) {
    //make a default answerr for every question:
    const blankSheet = {};
    for (let question of form.questions) {
      blankSheet[question.uniqueID] = '';
    }
    //creates a new answer sheet for the user
    const newAnswerSheet: CompletedForm = {
      creationDate: Date.now(),
      lastEdited: Date.now(),
      formID: form.id,
      originator: this.userService.userID,
      canWrite: [this.userService.userID],
      canRead: [this.userService.userID],
      answers: blankSheet,
    }
    //now set the form and answer sheet:
    this.selectedForm = form;

    this.answerSheet = newAnswerSheet;
    //must make initial save in order for auto-save
    this.afs.collection('completedForms').add(this._answerSheet).then(ref => {
      this._answerSheetID = ref.id;
      this.buildFormGroup();
    }).catch(console.error)

  }

  /**
  When previously-saved answers are opened, the connected form must be identified and opened as well
  */
  openForm(answers: CompletedForm) {
    if (!answers.id) {
      throw new Error('Cannot open a form that does not have an id');
    }
    this._answerSheetID = answers.id;

    this.answerSheet = answers;
    //find the associated form and open it:
    //NOTE( the file where the forms are saved can depend on the app, so it is stored in the TemplateService)

    let formID = answers.formID;
    console.log(formID)
    //iterate through each section:
    for (let section of this.templateService.formLibrary) {
      //check for formID:
      if (section.forms[formID]) {
        this.selectedForm = section.forms[formID];
        this.buildFormGroup(this.answerSheet);
        return;
      }
    }
    throw new Error('Form was not found in connection with selected answer sheet.')
  }



  /**
  Saves the curently-open form in the completedForms collection,
  */
  saveForm(): Promise<void> {
    this.extractAnswers();
    this._answerSheet.lastEdited = Date.now();

    //answerSheetID should be
    if (this._answerSheetID) {
      return this.afs.doc('completedForms/' + this._answerSheetID).update(this._answerSheet).then(_ => {
        console.log('answers saved');
      }).catch(console.error)
    } else {
      console.error('Attempting to save with undefined answerSheetID')
    }


  }
}
