/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-09T10:00:25+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const BUYER: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '3_2',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Buyer\'s Journey',

  text: [
    //this text is formatted and can even take HTML
    {
      html: 'BUYER\'S JOURNEY',

      style: 'heading'
    },
    'The buyer’s journey is often framed in the following stages: Awareness > Consideration > Decision.',

    'During the AWARENESS stage, prospects are experiencing and expressing symptoms of a problem or opportunity. They’re doing educational research to more clearly understand, frame, and define their problem or desire.',

    'During the CONSIDERATION stage, prospects are now clear about their problem or desire and are committed to researching and understanding all of the available approaches or methods to address their defined problem or desire.',

    'During the DECISION stage, prospects have decided on the approach or method they wish to pursue and are researching the available vendors, service providers, or products in their given solution strategy. They continue researching to whittle the long list down to a short list and, ultimately, make a final decision',

    'As you communicate with prospects in each stage, you’ll be providing them with different materials and information, appropriately presented for the stage they are in.'
  ],
  questions: [
    new FormTextControl(
      {
        uniqueID: '3-2-q1',
        label: 'What other changes do you need to consider making to align with your brand identity, USP, & chosen target market(s)?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '3-2-q2',
        label: 'What information and materials do your “Consideration” stage prospects require from you?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '3-2-q3',
        label: 'What information and materials do your “Decision” stage prospects require from you?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '3-2-q4',
        label: 'How is your current marketing / selling process aligned with the buyer’s journey?',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '3-2-q5',
        label: 'What changes do you need to make to your current marketing / selling process to make it better aligned with the buyer’s journey?',
        multiline: true
      }
    ),
  ]
}
