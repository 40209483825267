/**
 * @Author: bnockles
 * @Date:   2021-03-05T22:42:59+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-05T22:43:22+09:00
 */



import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PluralPipe } from './custom-pipes';



@NgModule({
  declarations: [PluralPipe],
  exports: [PluralPipe],
  imports: [
    CommonModule
  ]
})
export class CustomPipesModule { }
