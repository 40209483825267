/**
 * @Author: bnockles
 * @Date:   2021-03-12T22:49:36+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-13T15:45:01+09:00
 */



import { Component, OnInit } from '@angular/core';
import { UserManagementService } from 'src/app/users/services/user-management.service';
import { Unsubscriber } from 'src/app/unsubscriber.component';
import { AppUser } from 'src/app/base/services/backend/user.service';
import { FormService, CompletedForm } from 'src/app/forms/form.service';
import { TpgFormReviewService } from '../../tpg-forms/tpg-form-review.service';

@Component({
  selector: 'app-tpg-user',
  templateUrl: './tpg-user.component.html',
  styleUrls: ['./tpg-user.component.scss']
})
export class TpgUserComponent extends Unsubscriber {

  targetUser: AppUser;
  userForms: CompletedForm[];

  constructor(private ums: UserManagementService, private formReviewService: TpgFormReviewService, ) {
    super();
  }

  ngOnInit(): void {
    this.anotherSubscription = this.ums.userTarget$.subscribe(
      appUser => {
        this.targetUser = appUser;
        this.formReviewService.queryFormsBy(appUser.id);
      }
    )

    this.anotherSubscription = this.formReviewService.formQueryResults$.subscribe(
      results => {
        this.userForms = results;
      }
    )
  }

}
