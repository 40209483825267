<div appFullView>
  <mat-card>
    <mat-card-title>
      <div class="client-header">
        <span>All Clients</span>
        <button
          mat-icon-button
          (click)='newClient()'
        >
          <mat-icon>
            add
          </mat-icon>
        </button>

      </div>

    </mat-card-title>
    <mat-card-content>
      <table
        mat-table
        [dataSource]="clientQueryResults"
        class="mat-elevation-z8"
      >

        <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

        <ng-container
          *ngFor='let columnID of displayedColumns'
          [matColumnDef]="columnID"
        >
          <th
            mat-header-cell
            *matHeaderCellDef
          > {{ columnNames[columnID].description }} </th>
          <td
            mat-cell
            *matCellDef="let element"
          >
            <ng-container *ngIf='columnNames[columnID].type == "date"'>
              {{ element[columnID] | date }}
            </ng-container>
            <ng-container *ngIf='columnNames[columnID].type == "text"'>
              {{ element[columnID] }}
            </ng-container>
            <ng-container *ngIf='columnNames[columnID].type == "email"'>
              <a [href]='"mailto:"+element[columnID]'>
                {{ element[columnID] }}
              </a>
            </ng-container>



          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="displayedColumns"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns;"
          (click)='onClientClick(row)'
        ></tr>
      </table>
    </mat-card-content>
  </mat-card>

</div>