/**
 * @Author: bnockles
 * @Date:   2021-03-10T21:43:01+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-14T11:42:02+09:00
 */

export const THEME_COLORS = {
  red: '#b71c1c',
  cyan: '#006064',
  lightBlue: '#01579b',
  blue: '#0d47a1',
  indigo: '#1a237e',
  purple: '#4a148c',
  deepPurple: '#311b92',
  deepOrange: '#bf360c',
  blueGray: '#263238',
}

export const APP_COLORS = {
  light: '#aabaca',
  dark: THEME_COLORS.blueGray,
  link: 'white',
  hoverLink: '#dadada',
}

export const APP_SETTINGS = {
  companyName: 'The Prepared Group',
  copyrightHolder: 'The Prepared Group',
  loginTagline: 'Creating a Marketing System to Predictably Generate More Revenue',
  optionsDefaultCheckbox: true,
  optionsDefaultMultiple: true,
  userManagerColumns: ['displayName', 'email', 'lastLogin'],
  userManagerColumnNames: {
    displayName: {
      description: 'Name',
      type: 'text',
    },
    email: {
      description: 'Email',
      type: 'email',
    },
    lastLogin: {
      description: 'Last login',
      type: 'date'
    }
  },
}
