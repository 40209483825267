/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-13T13:54:14+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';
import { FormTableControl } from '../../../forms/form-table/form-table.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const FINANCIALA: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '1_2a',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Financial goals',
  text: [

    'Business growth is directly linked to bringing more money your way. But “more money” is rarely the actual goal. Instead\, it\’s what that money can do for you - either personally or professionally.',

    'Personally, you might want more time freedom away from your business to travel with your family. Maybe you\’ve got a major purchase in your future like a new home. Or\, perhaps you\’d like to replace your spouse\’s income so they can pursue their own goals.',

    'Professionally, you may be looking to expand your team or invest in assets like real estate or new equipment.',

    'In this exercise, define one or more specific goals you wish to achieve and the money needed to achieve / acquire / access them. Define the period of time over which you wish to reach these goals.'

  ],
  /**
  questions. This is where you will do most of your work. Questions define what the user will see AND how they need to  answer. YOu can use text or multiple choice questions.
  */
  questions: [
    /**
    This first question might be most common for you. It is for short answers, such as "what is your name?"
    */
    new FormTextControl(
      {
        uniqueID: '1-2a-q1',
        label: "What is your timeline in years for achieving the following goals?",
        multiline: true
      }
    ),
    new FormTableControl(
      {
        /**
        here, the unique ID must be UNIQUE

        <section name>-<description>
        */
        uniqueID: '1-2a-t1',
        label: "Your Wish List",
        rowHeaders: ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6', 'Item 7', 'Item 8', 'Item 9', 'Item 10'],
        columnHeaders: ['Thing You Want', 'Price', 'X Frequency', '= Total Cost']
      }
    ),
    new FormTextControl(
      {
        uniqueID: '1-2a-q2',
        label: "Total cost of above goals =",
        multiline: false
      }
    ),
  ]
}
