<mat-list>

  <mat-list-item
    appHoverCursor
    *ngFor="let completedForm of completedFormQueryResult"
    (click)=editForm(completedForm)
  >
    <mat-icon mat-list-icon>feed</mat-icon>
    <div mat-line>
      <div>{{completedForm.title}}
      </div>

    </div>
    <div mat-line> Last opened: {{completedForm.lastEdited | date}}</div>
    <div mat-line> {{completedForm.completedAnswers}}/{{completedForm.totalQuestions | plural  : 'question'}} completed</div>
  </mat-list-item>


</mat-list>