/**
 * @Author: bnockles
 * @Date:   2020-08-04T13:16:53+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-05T23:36:47+09:00
 */



/**
Should be extended by every FeatureModule's lead component:
subscribe by calling addSubscription
All subscriptions are automatically destroyed
*/


import { Subscription } from 'rxjs';

import { OnDestroy, HostListener, Component } from '@angular/core';

@Component({
  selector: 'unsub',
  template: '',
  styleUrls: []
})
export class Unsubscriber implements OnDestroy {


  private _subscription: Subscription = new Subscription();

  constructor() { }

  //-----------------------------------------------------------------
  // this will unsubscribe when Angular destroys the components
  // and whenever there are browser events which cause the app to
  // be destructed.
  //-----------------------------------------------------------------
  @HostListener('window:beforeunload')
  ngOnDestroy() {
    //console.log("Unsubscriber ngOnDestroy CALLED!",this);
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  protected resetSubscriptions() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }

  addSubscription(sub: Subscription) {
    this._subscription.add(sub);
  }

  set anotherSubscription(sub: Subscription) {
    this._subscription.add(sub);
  }

}
