<ng-container  #formView  *ngIf='formGroup else noOpenForm'>

  <div appHeader='2' class="d-flex" style="margin-left:-3px">
    <button
      mat-icon-button
      color="accent"
      aria-label="Back to form library"
      (click)='goBack()'
    >
      <mat-icon>arrow_back</mat-icon>
    </button>{{form.title}}
  </div>
  <div
    style="text-align: justify"
    appTextElement
    *ngFor='let paragraph of form.text'
    [textContent]='paragraph'
  >

  </div>
  <form [formGroup]='formGroup'>
    <ng-container *ngFor='let question of formGroup.controls| keyvalue: sortOrderAscOrder let i=index'>
      <!--------------------------------------------------------
        SELECT-BASED QUESTIONS
       ----------------------------------------------------------------------->
      <ng-container *ngIf='question.value.formComponent == "select"'>
        <app-form-options
          [label]='question.value.label'
          [helpText]='question.value.helpText'
          [options]='question.value.options'
          [expositoryText]='question.value.text'
          [formControlName]='question.key'
        >

        </app-form-options>
      </ng-container>
      <!--------------------------------------------------------
        TEXT-BASED QUESTIONS
       ----------------------------------------------------------------------->
      <ng-container *ngIf='question.value.formComponent == "text"'>
        <app-form-text
          [multiline]=question.value.multiline
          [label]='question.value.label'
          [helpText]='formGroup.controls[question.key].helpText'
          [expositoryText]='question.value.text'
          [formControlName]='question.key'
        >

        </app-form-text>
      </ng-container>
      <!--------------------------------------------------------
        TABLE-BASED QUESTIONS
       ----------------------------------------------------------------------->
      <ng-container *ngIf='question.value.formComponent == "table"'>
        <app-form-table
          [label]='question.value.label'
          [helpText]='formGroup.controls[question.key].helpText'
          [expositoryText]='question.value.text'
          [formControlName]='question.key'
          [rowHeaders]='question.value.rowHeaders'
          [columnHeaders]='question.value.columnHeaders'
        >

        </app-form-table>
      </ng-container>

    </ng-container>

    <ng-container *ngIf='form.conclusionText'>
      <div
        *ngFor='let paragraph of form.conclusionText'
        appTextElement
        [textContent]='paragraph'
      >
      </div>
    </ng-container>

    <div class="action-row" style="justify-content: space-between">
      <button
        mat-raised-button
        color="primary"
        (click)='saveForm()'
      >Save</button>
      <button
        mat-raised-button
        color="primary"
        (click)='print()'
      >Print</button>
      <button
        mat-raised-button
        color="primary"
        (click)='generatePdf()'
      >Generate PDF</button>
    </div>

  </form>






</ng-container>
<ng-template #noOpenForm>
  No forms are currently open.
</ng-template>
