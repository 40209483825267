/**
 * @Note: Parts of a file that are inside these "*" are only comments. You can use comments to write whatever you need for your own reference. I this file (as well as the sample file) I have written numerous comments to help you remembeer important ideas about usage.
 * @Author: bnockles
 * @Date:   2021-03-09T09:12:14+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-09T10:00:25+09:00
 */

/***************************************************
IMPORTS
*************************************************/
import { FormModel } from '../../../forms/form-model';
import { FormOptionsControl } from '../../../forms/form-options/form-options.component';
import { Validators } from '@angular/forms';
import { FormQuestion } from '../../../forms/form-question';
import { FormTextControl } from '../../../forms/form-text/form-text.component';


/***************************************************
A sample form: "SAMPLE_FORM"

"export" - means this field can be imported in other files, (namely, tpg.form-library.ts) DON'T CHANGE this.

"const" - this is an abbreviation for "constant". It is simply a way of declaring a static (unchanging) value. DON'T CHANGE this.

"SAMPLE_FORM" - the name of the value. Use names (without spaces) that privide a clear context . For example, "ONBOARDING_FORM_1" or "NEEDS_ASSESSMENT". you CAN CHANGE THIS, but if you do, make sure you update the variable name in files that also import it.

" : FormModel " - This tells the compiler  ( the thing running your program ) what kind of data to expect. I have programmed FormModel to expect all the fields  you need. If on of the fields is missing, you might get an error (a helpful reminder that your code is incomplete)

*************************************************/
export const SHORTNURTURE: FormModel = {

  /**
  The ID must be unique and it is best if you follow standard conventions by making the first letter lowercase and not using spaces. Also, don't use '/" or "." in your ID because those have specific programming meanings'
  "<section name>-<form description>"
  */
  id: '3_7',

  /**
  Title. I feel like this needs no explanation.
  */
  title: 'Short Term Nuture',

  text: [
    //this text is formatted and can even take HTML
    {
      html: 'SHORT-TERM NURTURE',

      style: 'heading'
    },
    'When prospects express interest in your lead magnet, you have a brief window of opportunity to follow-up while you are top of mind. A short-term follow-up process is the best way to move them forward toward a transaction.',

    'Your job is to connect the dots between the action they initially took (requesting your lead magnet in exchange for their contact information) and the next step (an appointment with you, an event registration, a purchase, etc.).',

  ],
  questions: [
    new FormTextControl(
      {
        uniqueID: '3-7-q1',
        label: "What are the most common questions prospects ask prior to their transaction?",
        helpText: '(Cost, offer details, timing questions, logistical issues, etc.)',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '3-6-q2',
        text: [
        'What are the top 3 to 5 concerns that need to be addressed (and in what order) so they will continue to move forward in the buying process?'
      ],
        label: 'Concern #1',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '3-6-q3',
        label: "What\'s the best medium or method to address this concern?",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '3-6-q4',
        label: 'Concern #2',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '3-6-q5',
        label: "What\'s the best medium or method to address this concern?",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '3-6-q6',
        label: 'Concern #3',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '3-6-q7',
        label: "What\'s the best medium or method to address this concern?",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '3-6-q8',
        label: 'Concern #4',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '3-6-q9',
        label: "What\'s the best medium or method to address this concern?",
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '3-6-q10',
        label: 'Concern #5',
        multiline: true
      }
    ),
    new FormTextControl(
      {
        uniqueID: '3-6-q11',
        label: "What\'s the best medium or method to address this concern?",
        multiline: true
      }
    ),
  ]
}
