/**
 * @Author: bnockles
 * @Date:   2021-03-12T20:21:30+09:00
 * @Last modified by:   bnockles
 * @Last modified time: 2021-03-13T22:32:03+09:00
 */



import { Injectable } from '@angular/core';
import { AngularFirestore, QueryFn, DocumentData } from '@angular/fire/firestore';
import { Observable, ReplaySubject } from 'rxjs';
import { AppUser } from 'src/app/base/services/backend/user.service';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {


  userQuery$ = new ReplaySubject<QueryFn<DocumentData>>();
  userQueryResults$ = new Observable<AppUser[]>();

  userTarget$ = new ReplaySubject<AppUser>();

  constructor(private afs: AngularFirestore) {

    //initial query is users who have logged in within the last two weeks:
    this.userQuery$.next(ref => ref.where('lastLogin', '>', 0));
    this.userQuery$.next(ref => ref.orderBy('lastLogin','desc'));

    // this.userQuery$.next(ref => ref.where('lastLogin', '>', Date.now() - 1210000000));

    //whenever the query changes, update the results
    this.userQueryResults$ = this.userQuery$.pipe(
      switchMap(
        queryFn => {
          return afs.collection<AppUser>('users', queryFn).valueChanges({ idField: 'id' })
        }
      )

    )
  }

  orderByColumn(column,order){
    this.userQuery$.next(ref => ref.orderBy(column,order));
  }

}
